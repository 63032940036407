<template>
  <div class="fragment h-100 d-flex direction-column">

    <div class="custom-row mt-auto">
      <div class="custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shops_emailPayPalForYour'])"></span>
        <WarningStatic class="mw-100 w-100">
          <template slot="title">
            skladusa.pp@gmail.com
          </template>
          <template slot="text">
            {{$t('shops_emailPayPalForYour.localization_value.value')}}
          </template>
        </WarningStatic>
      </div>
    </div>

  </div>
</template>

<script>
  import WarningStatic from "../../../../../UI/alerts/WarningStatic/WarningStatic";

  export default {
    name: "EbayShop",
    components: {
      WarningStatic,
    },

    props:['shopEbay'],

    data(){
      return {
        data: this.shopEbay
      }
    },

    methods: {
      changeData() {
        this.$emit('input', this.data)
      }
    }
  }
</script>

<style lang="scss">

</style>
