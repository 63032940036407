<template>
  <div class="toggle-block__details-guide"
       v-if="guideItem.getLinkEn() || guideItem.getLinkRu() || guideItem.getLinkUa()
          || guideItem.getTextEn() || guideItem.getTextRu() || guideItem.getTextUa() || isAdmin"
       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
    <span class="admin-edit" @click="editTranslate(['shops_viewGuide'])"></span>
    <span v-if="isAdmin" class="guide-edit-btn" @click="isModalGuidePopup = true"></span>
    <template v-if="$store.getters.GET_LANG === EN_LANG">
      <a
          v-if="guideItem.getLinkEn()"
          class="site-link site-link--alt"
          target="_blank"
          :href="guideItem.getLinkEn()"
      >
        {{ $t('shops_viewGuide.localization_value.value') }}
      </a>
      <span
          v-else-if="guideItem.getTextEn()"
          class="site-link site-link--alt"
          style="cursor: pointer;"
          @click="isModalShowReadGuidePopup = true"
      >
            {{ $t('shops_viewGuide.localization_value.value') }}
          </span>
      <span
          v-else-if="isAdmin"
          class="site-link site-link--alt"
          style="cursor: pointer;"
          @click="isModalGuidePopup = true"
      >
            {{ $t('shops_viewGuide.localization_value.value') }}
          </span>
    </template>

    <template v-if="$store.getters.GET_LANG === RU_LANG">
      <a
          v-if="guideItem.getLinkRu()"
          class="site-link site-link--alt"
          target="_blank"
          :href="guideItem.getLinkRu()"
      >
        {{ $t('shops_viewGuide.localization_value.value') }}
      </a>
      <span
          v-else-if="guideItem.getTextRu()"
          class="site-link site-link--alt"
          style="cursor: pointer;"
          @click="isModalShowReadGuidePopup = true"
      >
            {{ $t('shops_viewGuide.localization_value.value') }}
          </span>
      <span
          v-else-if="isAdmin"
          class="site-link site-link--alt"
          style="cursor: pointer;"
          @click="isModalGuidePopup = true"
      >
            {{ $t('shops_viewGuide.localization_value.value') }}
          </span>
    </template>

    <template v-if="$store.getters.GET_LANG === UA_LANG">
      <a
          v-if="guideItem.getLinkUa()"
          class="site-link site-link--alt"
          target="_blank"
          :href="guideItem.getLinkUa()"
      >
        {{ $t('shops_viewGuide.localization_value.value') }}
      </a>
      <span
          v-else-if="guideItem.getTextUa()"
          class="site-link site-link--alt"
          style="cursor: pointer;"
          @click="isModalShowReadGuidePopup = true"
      >
            {{ $t('shops_viewGuide.localization_value.value') }}
          </span>
      <span
          v-else-if="isAdmin"
          class="site-link site-link--alt"
          style="cursor: pointer;"
          @click="isModalGuidePopup = true"
      >
            {{ $t('shops_viewGuide.localization_value.value') }}
          </span>
    </template>

    <GuideEditPopup
        v-if="isModalGuidePopup"
        :guideItem="guideItem"
        :edit="edit"
        :hideVideo="true"
        @close="isModalGuidePopup = false"
        @save="saveGuide"
    />

    <ReadGuideViewPopup
        v-if="isModalShowReadGuidePopup"
        :guideItem="guideItem"
        @close="isModalShowReadGuidePopup = false"
    />
  </div>
</template>

<script>
import GuideEditPopup from "../../../../../coreComponents/GuideBlock/popups/GuideEditPopup";
import ReadGuideViewPopup from "../../../../../coreComponents/GuideBlock/popups/ReadGuideViewPopup";
import {Guide} from "../../../../../globalModels/Guide";
import {EN_LANG, RU_LANG, UA_LANG} from "../../../../../../staticData/staticVariables";
import {guideMixin} from "../../../../../../mixins/guideMixins/guideMixin";

export default {
  name: "ToggleGuideBlock",
  components: {ReadGuideViewPopup, GuideEditPopup},
  props: {
    shopGuideKey: String,
  },

  mixins: [guideMixin],

  watch: {
    'shopGuideKey' () {
      this.initGuide()
    }
  },

  created() {
    this.initGuide()
  },

  data() {
    return {
      isModalGuidePopup: false,
      isModalShowReadGuidePopup: false,
      guideItem: new Guide(),
      edit: false,
      EN_LANG: EN_LANG,
      RU_LANG: RU_LANG,
      UA_LANG: UA_LANG,
    }
  },

  methods: {
    initGuide() {
      if (this.shopGuideKey) {
        this.$store.dispatch('getGuideByKey', this.shopGuideKey).then((response) => {
          let respData = this.getRespData(response)

          if (respData.length) {
            this.guideItem.setGuideItem(respData[0])
            this.edit = true
          }
          else {
            //reset item if no guide for this key
            this.guideItem = new Guide()
            this.guideItem.setChapterKey(this.shopGuideKey)
            this.edit = false
          }
        })
      }
      else {
        this.guideItem = new Guide()
        this.edit = false
      }
    }
  },
}
</script>

<style scoped>
.toggle-block__details-guide {
  position: relative;
}

.toggle-block__details-guide:hover .guide-edit-btn {
  opacity: 1;
  z-index: 2;
  transition: 0.3s 0.3s;
}
</style>