<template>
  <div class="fragment h-100 d-flex direction-column">

    <div class="custom-row">
      <div class="custom-col custom-col--xl-100 d-flex my-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shops_linkShopName', 'shops_linkShopNameTxt'])"></span>
        <DefaultInput
                class="white w-100"
                v-bind:class="{'ui-no-valid': dataValidate.validation.linkShopName}"
                :error="dataValidate.validation.linkShopName"
                :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.linkShopName : $t(`${dataValidate.validationTranslate.linkShopName}.localization_value.value`)"
                :type="'text'"
                :label="$t('shops_linkShopName.localization_value.value')"
                :value="data.linkShopName"
                @change="changeLinkShopName"
        />
        <TooltipTextHelperPlugin>
          <template slot="text">
            <TooltipBtn/>
          </template>
          <template slot="paragraph">
            {{$t('shops_linkShopNameTxt.localization_value.value')}}
          </template>
        </TooltipTextHelperPlugin>
      </div>

    </div>
  </div>
</template>

<script>

  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
  import TooltipBtn from "@/components/UI/tooltips/TooltipBtn/TooltipBtn";
  import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";
  export default {
    name: "UkrainiantreasuresShop",
    components: {
      TooltipTextHelperPlugin,
      TooltipBtn,
      DefaultInput
      // DefaultInput,
    },

    props:['shopUkrainiantreasures', 'validate', 'isEdit', 'shopName'],

    data(){
      return {
        data: this.shopUkrainiantreasures,
        dataValidate: this.validate
      }
    },

    created() {
      if(this.shopName.length > 0) {
        this.data.linkShopName = this.shopName.replaceAll(' ', '')
      }
    },

    watch: {
      validate(validate) {
        this.dataValidate = validate
      },
    },

    methods: {
      changeLinkShopName(val) {
        this.data.linkShopName = val.replaceAll(' ', '')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .my-col {
    max-width: 400px;
  }
</style>
