<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['shops_allShop'])"></span>
      <div class="content-tabs">
        <template v-for="(item, index) in dataTypeShops">
          <div class="content-tabs__item "
               v-if="!(!_.has(currentPermissions, PERMISSIONS.SHOPS_UKRAINIAN_TREASURES_TYPE) && item.shop.name === $store.getters.getDefaultShopsTypes.ukrainiantreasures.type)"
               :key="index"
               v-bind:class="{active: item.activeTab}"
               @click="$emit('changeShopType', index)">
            {{item.shop.currentTranslate.label}}
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
        <!--<ActivateDateButton @changeDate="changeDate" :value="$store.getters.getShopsFilterDate" class="ml-3" />-->
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['common_AddNew'])"></span>
        <router-link :to="$store.getters.GET_PATHS.mainSettingsShopsCreate">
          <MainButton class="btn-fit-content"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import ActivateDateButton from "../../../../../../UI/buttons/ActivateDateButton/ActivateDateButton";


  export default {
    name: "ShopsHead",
    components: {
      MainButton,
      ToggleFilterButton,
      PlusIconSVG,
      // ActivateDateButton,
    },

    props: [
      'showFilter', 'typeShops', 'countFilterParams'
    ],

    watch: {
      typeShops(newVal) {
        this.dataTypeShops = newVal
      }
    },

    created() {

    },

    data() {
      return {
        dataTypeShops: this.typeShops,
      }
    },



    methods: {

      // changeDate(date) {
      //   let data = {}
      //
      //   if(date[0] === null && date[1] === null){
      //     data.date = ''
      //   } else {
      //     data.date = date[0] + ',' + date[1]
      //   }
      //
      //   this.$emit('changeFilter', data)
      // },

    }
  }
</script>

<style scoped>

</style>
