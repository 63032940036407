<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
          :backgroundImage="'shops'"
          class="shops"
      >
        <template slot="body">
          <div class="card-detail-left__caption"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_payPalEmailToUseIs', 'shops_thisShopIsAdctivated', 'shops_thisShopIsNotActivated', 'shops_thisShopIsCanceled'])"></span>
            {{$t('shops_payPalEmailToUseIs.localization_value.value')}}
            <v-popover
                offset="5"
                placement="top"
                trigger="hover"
                class="inline-block"
            >
              <span>
               <TooltipBtn></TooltipBtn>
              </span>
              <template slot="popover">
                <p class="mb-0 mt-1" v-if="$store.getters.getShopsItem.shop_status === SHOPS_ADMIN_TYPES.valid"> {{$t('shops_thisShopIsAdctivated.localization_value.value')}}</p>
                <p class="mb-0 mt-1" v-if="$store.getters.getShopsItem.shop_status === SHOPS_ADMIN_TYPES.notValid"> {{$t('shops_thisShopIsNotActivated.localization_value.value')}}</p>
                <p class="mb-0 mt-1" v-if="$store.getters.getShopsItem.shop_status === SHOPS_ADMIN_TYPES.cancel"> {{$t('shops_thisShopIsCanceled.localization_value.value')}}</p>
              </template>
            </v-popover>

          </div>
          <div class="card-detail-left__mail">
            skladusa.pp@gmail.com
          </div>
          <div class="card-detail-left__date">
            {{ $store.getters.getShopsItem.created_at | moment("DD MMM, YYYY") }}
          </div>

          <div class="site-link brown text-decoration-underline mt-3">
            <div v-if="isAdmin && $store.getters.getShopsItem.activityUUID"
                 class="mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
              <div class="site-link text-decoration-underline btn-style brown"
                   @click="historyOfChangesFunc('shops', $store.getters.getShopsItem.activityUUID)">
                {{$t('common_CheckHistory.localization_value.value')}}
              </div>
            </div>
          </div>

        </template>
      </CardLeftBlock>
    </div>
    <div class="detail-page__right">
      <ShopsEditing
              v-if="$store.getters.getShopTypesParent.length > 0 && _.has($store.getters.getShopsItem, 'id')"
              @reloadShop="reloadShop"
      />
    </div>
  </div>

</template>

<script>

  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import ShopsEditing from "./ShopsEditing/ShopsEditing";
  import {SHOPS_ADMIN_TYPES} from "../../../../../../staticData/staticVariables";

  export default {
    name: "ShopsEditingUser",
    components: {
      CardLeftBlock,
      TooltipBtn,
      ShopsEditing,
    },

    data() {
      return{
        SHOPS_ADMIN_TYPES: SHOPS_ADMIN_TYPES
      }
    },

    mounted() {
      this.reloadShop()
      // this.$store.commit('clearShopsItem')
      // this.$store.dispatch('getShops', this.$route.params.id)
    },

    methods: {
      reloadShop() {
        this.$store.commit('clearShopsItem')
        this.$store.dispatch('getShops', this.$route.params.id)
      },
    }

  }
</script>

<style lang="scss">

</style>
