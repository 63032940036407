<template>
  <div class="radio-label-picture">
    <input type="radio"
           class="radio-label-picture__input"
           :id="'radio-default-' + id"
           :checked="checked"
           :disabled="disabled"
           @change="change"
           :name="name"
    >
    <label :for="'radio-default-' + id" class="radio-label-picture__label">
      <span class="radio-label-picture__bg"></span>
      <div class="radio-label-picture__label-content">

        <span class="radio-label-picture__ico">
          <slot name="ico">

          </slot>
        </span>

        <span class="radio-label-picture__txt">

          {{ label }}
        </span>

        <span class="radio-label-picture__arrow" v-if="arrow === true">
          <ArrowRightBrownIco/>
        </span>

      </div>
    </label>

    <div class="radio-label-picture__help-text">
      {{ helpText }}
    </div>

    <span class="radio-label-picture__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>
  </div>
</template>

<script>
  import ArrowRightBrownIco from '../../../../../public/img/UI-group/arrow-right-brown.svg?inline'


  export default {
    name: "RadioLabelPicture",
    components: {
      ArrowRightBrownIco,
    },

    data () {
      return {
        id: null,
        checked: this.value,
      }
    },

    props: [
      'label',
      'value',
      'helpText',
      'errorTxt',
      'error',
      'disabled',
      'name',
      'arrow'
    ],

    mounted () {
      this.id = this._uid
    },

    watch: {
      value(newVal) {
        this.checked = newVal
      }
    },

    methods: {
      change: function() {
        this.$emit('change', this.value);
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";
  @import "../../../../scss/colors";


  .radio-label-picture{
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    padding-right: 20px;

    &.radio-off{
      margin-right: 38px;

      @include for-550{
        margin-right: 20px;
        padding-right: 5px;
      }

      .radio-label-picture__label{
        padding-left: 6px;

        &:before,
        &:after{
          display: none;
        }
      }

      .radio-label-picture__bg{
        left: 0;
        width: calc(100% + 24px);
        padding-left: 12px;

        @include for-550{
          width: calc(100% + 10px);
        }
      }
    }

    &__arrow{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -45px;


      @include for-550{
        margin-right: 5px;
        right: -25px;
      }
    }

    &__input{
      display: none;
    }


    &__bg{
      background: white;
      border: 1px solid $mainBg;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px #FDF9F3;
      border-radius: 5px;
      position: absolute;
      left: 24px;
      top: -6px;

      display: flex;
      width: calc(100% - 12px);
      height: calc(100% + 12px);
    }

    &__label-content{
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
    }

    &__label{
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      font-size: 14px;
      line-height: 21px;
      color: $black;


      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 15px;
        height: 15px;
        border: 1px solid #BBAD9C;
        box-sizing: border-box;
        display: flex;
        border-radius: 50%;
      }


      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 15px;
        height: 15px;
        border: 1px solid transparent;
        display: flex;
        opacity: 0;
        border-radius: 50%;
        transform: scale(0.6);
        background-color: $brown;
      }
    }

    &__input:checked + &__label{
      &:before{
        /*background-color: $brown;*/
        border-color: $brown;
      }

      &:after{
        opacity: 1;
      }
    }


    &__input:disabled + &__label{
      &:before{
        opacity: 0.4;
      }
      &:after{
        opacity: 0;
      }
    }

    &__input:disabled:checked + &__label{

      &:before, &:after{
        opacity: 0.4;
      }
    }

    &__help-text{
      position: absolute;
      top: 100%;
      left: 24px;
      font-size: 12px;
      line-height: 18px;
      color: $borderBrown;
    }

    &__error{
      position: absolute;
      //bottom: -18px;
      margin-top: 10px;
      left: 0;
      font-size: 11px;
      line-height: 13px;
      color: $orange;
    }


    &__ico{
      display: inline-flex;
      align-items: flex-end;
    }

    &__txt{
      padding-left: 5px;
    }

    &.w-150{
      .radio-label-picture__label {
        max-width: 150px;
        width: 100%;

        @include for-550{
          max-width: 100%;
        }

      }


      .radio-label-picture__ico{
        max-width: 24px;
        max-height: 24px;

        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .radio-label-picture__bg{
        top: -10px;
        height: calc(100% + 20px);
      }

      .radio-label-picture__txt{
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        width: 100%;

        @include from-550 {
          max-width: 95px;
        }
      }
    }
  }

  .empty-label{
    min-height: 16px;
  }
</style>
