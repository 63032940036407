<template>
  <div class="warning-static">
    <div class="warning-static__ico">
      <WarningIco/>
    </div>
    <div class="warning-static__info">
      <div class="warning-static__title">
        <slot name="title">

        </slot>
      </div>
      <div class="warning-static__text">
        <slot name="text">

        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  import WarningIco from '../../../../../public/img/common/warning-icon.svg?inline'

  export default {
    name: "WarningStatic",
    components: {
      WarningIco
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

  .warning-static{
    display: flex;
    padding: 15px;
    background: $mainBg;
    border: 1px solid $borderBrown;
    border-radius: 5px;
    width: fit-content;

    &__ico{
      max-width: 24px;
      width: 100%;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      svg{
        width: 100%;
        height: 100%;
      }
    }

    &__info{
      width: 100%;
    }

    &__title{
      color: $brown;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 4px;
      font-weight: bold;
    }

    &__text{
      color: $brown;
      font-size: 14px;
      line-height: 18px;
    }

  }
</style>