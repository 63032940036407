<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['shops_unverified', 'shops_verified', 'shops_cancel'])"></span>
      <div class="content-tabs content-tabs--separator">
        <template>
          <div class="content-tabs__item"
               v-for="(item, index) in adminTabs" :key="index"
               @click="changeTab(item.tabType)"
               v-bind:class="{active: item.active}"
          >
            {{item.name}}
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['common_AddNew'])"></span>
        <router-link :to="$store.getters.GET_PATHS.mainSettingsShopsCreate">
          <MainButton class="btn-fit-content"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'


  export default {
    name: "ShopsHead",
    components: {
      MainButton,
      ToggleFilterButton,
      PlusIconSVG,
    },

    props: [
      'showFilter', 'adminTabs', 'countFilterParams'
    ],

    watch: {

    },

    created() {

    },

    data() {
      return {
        searchField1: '',
        searchField2: '',
        searchField3: '',
        searchField4: '',


        isShowSearchEngineBlock: false,
      }
    },



    methods: {

      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },

      changeTab(val) {
        this.$emit('changeTab', val)
      },

    }
  }
</script>

<style scoped>

</style>
