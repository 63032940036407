
export const shopDetails = {
  methods: {
    shopTypeFunc() {
      let parent = [];
      let child = [];
      let childWithActive = [];

      this.$store.getters.getShopsTypes.map((item) => {
        if(item.parent_type_id === null) {
          parent.push({
            active: false,
            activeTab: false,
            shop: item
          })
        } else {
          let newItem = item
          newItem.label = item.currentTranslate.label
          child.push(newItem)
          childWithActive.push({
            active: false,
            activeTab: false,
            shop: item
          })
        }

        if(parent.length === 1) {
          parent[0].active = true
        }
      })


      this.$store.commit('setShopTypesParent', parent)
      this.$store.commit('setShopTypesChild', child)
      this.$store.commit('setShopTypesChildWithActive', childWithActive)

      // return {parent, child}
    },

    removeShopTypeByName(value, array) {
      let newVal = this._.cloneDeep(value)
      newVal.map((item, index) => {
        array.map((arrayItem) => {
          if(item.shop.name === arrayItem) {
            delete newVal[index]
            console.log(2342);
          }
        })
      })

      return newVal
    },
  }
}
