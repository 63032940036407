<template>

  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt mb-2">
          <a class="table-link btn-style word-break"
             v-if="_.has(item.shop_param, 'shop_link')"
             :href="item.shop_param.shop_link"
             target="_blank"
          >{{ item.shop_param.shop_link }}</a>
        </div>
        <div class="table-card__item-head-txt">
          <span class="text-decoration-underline">
                {{item.user.user_personal_contact.name}}
                {{item.user.user_personal_contact.last_name}}
          </span>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('shops_created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('shop_setGAKey.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <StatusIcoBtn
                      v-if="item.shop_g_a_params && item.shop_g_a_params.is_ga_view_key === 1"
                      :type="'delivered'"
              />
              <StatusIcoBtn
                      v-else
                      :type="'cancel'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('shops_shopName.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.shop_name }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('shop_type.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex align-items-end">
              <v-popover
                      class="site-tooltip"
                      offset="5"
                      placement="top-left"
                      trigger="hover"
              >
                <span>
                  <StatusIcoBtn
                          :type="item.shop_status"
                          class="mr-1"
                  />
                </span>
                <template slot="popover"
                          v-if="item.shop_status === 'valid'"
                >
                  <div class="status-tooltip">
                    <StatusIcoBtn :type="item.shop_status" class="mr-2"/>
                    Verified
                  </div>
                </template>
                <template slot="popover"
                          v-if="item.shop_status === 'not_valid'"
                >
                  <div class="status-tooltip brown-tooltip">
                    <StatusIcoBtn :type="item.shop_status" class="mr-2"/>
                    Declined
                  </div>
                  <p class="mb-0 mt-1">This shop was not approved by administrator</p>
                </template>
                <template slot="popover"
                          v-if="item.shop_status === 'cancel'"
                >
                  <div class="status-tooltip brown-tooltip">
                    <StatusIcoBtn :type="item.shop_status" class="mr-2"/>
                    In progress
                  </div>
                  <p class="mb-0 mt-1">This shop is under consideration</p>
                </template>
              </v-popover>

              <span class="table-ico">
                      <span v-if="item.shop_type !== null && item.shop_type.name === 'nothing_above'">Other</span>
                <span v-if="item.shop_type !== null && item.shop_type.name === 'shopify'">Shopify</span>
                <span v-if="item.shop_type !== null && item.shop_type.name === 'raverly'">Raverly</span>
                <span v-if="item.shop_type !== null && item.shop_type.name === 'tilda'">Tilda</span>
                <span v-if="item.shop_type !== null && item.shop_type.name === 'woocommerce'">Wooco</span>
                <span v-if="item.shop_type !== null && item.shop_type.name === 'opencart'">Opencart</span>
                <span v-if="item.shop_type !== null && item.shop_type.name === 'wish'">Wish</span>
                <img v-if="item.shop_type !== null && item.shop_type.name === 'etsy'"
                     src="/img/shops-group/etsy-shop-icon.png" alt="shop-img">
                <img v-if="item.shop_type !== null && item.shop_type.name === 'amazonmws'"
                     src="/img/shops-group/amazon-shop-icon.png" alt="shop-img">
                <img v-if="item.shop_type !== null && item.shop_type.name === 'ebay'"
                     src="/img/shops-group/ebay-shop-icon.png" alt="shop-img">
                    </span>

              <span class="status--inactive white-space-line ml-2">
                 <div class="case-cart__content status--inactive"
                      v-if="item.shop_oauth_param.key_and_token_exist !== 0"
                      @click="$emit('removePermission', item.id)">
                  {{$t('shops_deletePermission.localization_value.value')}}
                </div>
              </span>
            </div>
          </div>

          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>

        </div>

        <div class="table-card__item-btn">
          <div v-if="item.shop_status === 'valid' && item.shop_type.name === 'ukrainiantreasures' &&
                    _.has(currentPermissions, PERMISSIONS.UKRAINIAN_TREASURES_SETTINGS)" class="table-card__item-btn-i">
            <LinkButton
                :value="$t('shops_settings.localization_value.value')"
                :type="'edit'"
                @click.native="$emit('editShopMkSettings')"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    @click.native="$emit('editShop')"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('deleteShop')"
            />
          </div>
          <div class="table-card__item-btn-i table-card__item-btn-i--mobile">
            <template v-for="(itemTab, index) in adminTabs">
              <MainButton
                      :value="$t('shops_accept.localization_value.value')"
                      @click.native="$emit('updateShopsStatusAccept', item.id)"
                      :key="index"
                      v-if="itemTab.active && itemTab.tabType === SHOPS_ADMIN_TYPES.notValid"
              />
              <MainButton
                      :value="$t('shops_decline.localization_value.value')"
                      @click.native="$emit('updateShopsStatusDeclined', item.id)"
                      :key="index"
                      v-if="itemTab.active && itemTab.tabType === SHOPS_ADMIN_TYPES.valid"
              />
              <MainButton
                      :value="$t('shops_accept.localization_value.value')"
                      @click.native="$emit('updateShopsStatusAccept', item.id)"
                      :key="index"
                      v-if="itemTab.active && itemTab.tabType === SHOPS_ADMIN_TYPES.cancel"
              />
            </template>
          </div>
        </div>

      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_mobileTableShowMore.localization_value.value')}}
      </div>
    </div>
  </div>

</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
  import {SHOPS_ADMIN_TYPES} from "../../../../../../../../staticData/staticVariables";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";

  export default {
    name: "ShopsTableMobile",

    components: {
      TableUserColumn,
      LinkButton,
      MainButton,
    },

    data() {
      return {
        show: false,
        hideEditField: false,
        SHOPS_ADMIN_TYPES: SHOPS_ADMIN_TYPES,
      }
    },

    props: ['item', 'adminTabs'],

    watch: {
      item(newVal) {
        this.item = newVal
      },
    },

  }
</script>

<style scoped>
  .table-card__item-btn-i--mobile .main-button {
    height: 30px;
    font-size: 12px;
  }
</style>
