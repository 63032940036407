
<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup send-email-popup"
  >
    <template slot="header">
      Send Email to Anna Bugrak
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <TextareaDefault
          class="mb-4 mh-225"
          :label="'Message'"
          :value="text"
        />
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex">
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4"
            @click="$emit('close')"
        >
          Cancel
        </span>

        <MainButton
            class="custom-popup__btn-i"
            :value="'Send to Client'"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";

  export default {
    name: "SendEmailPopup",
    components: {
      Modal,
      MainButton,
      TextareaDefault,
    },

    data() {
      return {
        text: 'Доброго дня! Ми отримали консолiдацiю за трек номером 392873852957, але через вiдсутнiсть створеной консолiдацii вiдправити не зможемо. Створiть консолiдацiю та повiдомте склад у вайбер або чат про це.\n\n ' +
          'Гарного дня,\n\n ' +
          'SkladUSA\n ' +
          'Rostislav\n ' +
          'Warehouse Manager'
      }
    },

    methods: {

    }
  }
</script>

<style lang="scss">
  .send-email-popup .modal-component__inner{
    max-width: 518px;
  }

  .mh-225 {
    max-width: 390px;

    textarea{
      min-height: 225px;
      padding-right: 15px;
    }
  }
</style>
