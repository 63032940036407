<template>
  <div class="fragment">

    <ShopsTablePageAdmin
        @filter="filter"
        :filterGetParams="filterGetParams"
        :typeShops="typeShops"
        @changeShopType="changeShopType"
        @changeTab="changeTab"
        @changeFilter="changeFilter"
        @showMore="showMore"
        @resetFilter="resetFilter"
        @downloadFiles="downloadFiles"
        :countFilterParams="countFilterParams"
        :typeShopsFilter="typeShopsFilter"
        :adminTabs="adminTabs"
        v-if="$store.getters.getCurrentUserRights.additionalPermissions &&
                $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"
    />

    <ShopsTablePageUser
        @filter="filter"
        :filterGetParams="filterGetParams"
        :typeShops="typeShops"
        @changeShopType="changeShopType"
        @changeFilter="changeFilter"
        @showMore="showMore"
        @resetFilter="resetFilter"
        @reload="reload"
        :countFilterParams="countFilterParams"
        :typeShopsFilter="typeShopsFilter"
        v-if="$store.getters.getCurrentUserRights.additionalPermissions &&
                !$store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"
    />
  </div>


</template>

<script>

  import ShopsTablePageUser from "./ShopsTablePageUser/ShopsTablePageUser";
  import {shopDetails} from "../../../../../mixins/shopMixins/shopTypeFunc";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import ShopsTablePageAdmin from "./ShopsTablePageAdmin/ShopsTablePageAdmin";
  import {SHOPS_ADMIN_TYPES} from "../../../../../staticData/staticVariables";

  export default {
    name: "ShopsTablePage",

    components: {
      ShopsTablePageUser,
      ShopsTablePageAdmin,
    },

    mixins: [shopDetails, routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getShopsForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterName: '',
        filterShop: '',
        filterShopId: '',
        filterVerify: '',
        filterShopLink: '',
        filterShopRefText: '',
        filterShopUser: '',
        filterUserId: '',
        typeShops: [],
        typeShopsFilter: [],
        filterGetParams: {},
        staticParentShopTypeName: 'other',
        countFilterParams: 0,

        adminTabs: [
          {
            name: this.$t('shops_unverified.localization_value.value'),
            active: true,
            tabType: SHOPS_ADMIN_TYPES.notValid,
          },
          {
            name: this.$t('shops_verified.localization_value.value'),
            active: false,
            tabType: SHOPS_ADMIN_TYPES.valid,
          },
          {
            name: this.$t('shops_cancel.localization_value.value'),
            active: false,
            tabType: SHOPS_ADMIN_TYPES.cancel,
          },
        ]
      }
    },

    created() {


      let interval = setInterval(() => {
        if(!this.$store.getters.getLoadingShops) {
          this.checkAddShopPermission()
          clearTimeout(interval)
        }
      }, 50)


      this.$store.dispatch('fetchShopsTypes').then(() => {
        this.shopTypeFunc()

        this.typeShops = this._.cloneDeep(this.$store.getters.getShopTypesParent)
        this.typeShops.unshift(
          {
            "active": false,
            "activeTab": true,
            "shop": {
              "id": 0,
              "name": "allShop",
              "parent_type_id": null,
              "translationStorage": {
                "en": { "label": "All Shop" },
                "ua": { "label": "Усі магазини" },
                "ru": { "label": "Все Магазины" }
              },
              "currentTranslate":
                { "label": this.$t('shops_allShop.localization_value.value')}
            }
          }
        )

        this.typeShopsFilter = this._.cloneDeep(this.$store.getters.getShopTypesParent)
        this.typeShopsFilter = this.typeShopsFilter.concat(this.$store.getters.getShopTypesChildWithActive)
        this.typeShopsFilter = this.removeShopTypeByName(this.typeShopsFilter, ['other', 'nothing_above'])

        this.loadContent()

      })
    },

    mounted() {

    },

    methods: {

      loadContent() {
        if(this.$route.query.shopId !== undefined) {
          this.changeShopType(parseInt(this.$route.query.shopId))
        } else if (this.$route.query.verify !== undefined) {
          if(!this.isAdmin) this.$router.push(this.$store.getters.GET_PATHS.notFound)
          this.changeTab(this.$route.query.verify)
        } else {
          this.filter()
        }
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          name: this.$route.query.name,
          shopId: this.$route.query.shopId,
          verify: this.$route.query.verify,
          shopLink: this.$route.query.shopLink,
          shopRefText: this.$route.query.shopRefText,
          shopUser: this.$route.query.shopUser,
          userId: this.$route.query.userId,

          // Ebay & Shopify API
          code: this.$route.query.code,
          state: this.$route.query.state,
          shop: this.$route.query.shop,
        }
      },

      changeFilter(data) {
        data.page = this.onePage
        data.count = this.forPage

        this.getFilterParam()
        this.changeRouteParams(this.filterGetParams, data)

        this.filter()
      },

      changeShopType(newIndex) {
        this.typeShops.map((item, index) => {
          this.typeShops[index].activeTab = false

          if(index === newIndex)
            this.typeShops[index].activeTab = true
        })

        if(newIndex === 0){
          this.changeFilter({shopId: ''})
        } else {
          this.changeFilter({shopId: newIndex})
        }
      },

      resetFilter() {
        this.$router.push({ path: '', query: {}})

        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        if(this.filterName !== '') myQuery.where('shopName', this.filterName)
        if(this.filterUserId !== '') myQuery.where('user', this.filterUserId)
        if(this.filterShopLink !== '') myQuery.where('shop_link', this.filterShopLink)
        if(this.filterShopRefText !== '') myQuery.where('reference_text', this.filterShopRefText)

        console.log(333333333333333);
        // console.log(this.typeShops[0].activeTab);
        // console.log(this.filterShopId);

        if(this.$store.getters.getIsAdminRights === 'admin'){
          myQuery.where('type', this.filterShop)
        }

        if(this.filterShopId !== '' && this.typeShops[0].activeTab === true) {
          myQuery.where('type', this.filterShopId)
        } else {
          this.typeShops.map((item) => {
            if(item.activeTab){
              if(item.shop.id !== 0 && item.shop.name !== this.staticParentShopTypeName){
                myQuery.where('type', item.shop.id)
              } else if (item.shop.name === this.staticParentShopTypeName) {
                myQuery.where('parent_type_id', item.shop.id)
              }
            }
          })
        }

        if(this.filterVerify !== ''){
          myQuery.where('shopStatus', this.filterVerify)
        } else if(this.$store.getters.getIsAdminRights === 'admin'){
          myQuery.where('shopStatus', SHOPS_ADMIN_TYPES.notValid)
        }


        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if(this.filterDate.length > 0){
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'between_start_end',
            date
          )
        }

        let url = this.generateGetParamsFromQuery(myQuery, forPage, page)

        // True if get next page
        if(next) this.$store.commit('setNextShopPage', true)

        // save filter params in store
        this.$store.commit('setShopsFilter', window.location.search)

        this.$store.dispatch('fetchShops', url).then(() => {
          // skip get next page
          this.$store.commit('setNextShopPage', false)
        })

        // Check Count Filter
        let checkCountFilterArray = ['verify', 'code', 'shop', 'userId']
        if((this.typeShops[0].activeTab !== true || this.filterShop !== '') &&
          !this.$store.getters.getCurrentUserRights.additionalPermissions[this.PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]) {
          checkCountFilterArray.push('shopId')
        }
        this.checkCountFilter(checkCountFilterArray)

      },

      showMore() {
        this.generateNextPAgeUrl(this)

        this.filter(true)
      },

      checkCountFilter(withoutParams) {
        this.countFilterParams = this.checkCountFilterFromUrl(this, withoutParams)
      },

      changeTab(tabType) {
        this.adminTabs.map((item) => {
          item.active = false

          if(item.tabType === tabType) item.active = true
        })

        this.changeFilter({verify: tabType})
      },

      savePermission(data, id, amazon = false){

        let storeAction = 'saveShopPermission'
        if (amazon) {
          storeAction = 'saveShopPermissionAmazon'
        }

        this.$store.dispatch(storeAction, {data: data, id: id}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            let shops = this.$store.getters.getShops

            shops.map((item, index) => {
              if(item.id === parseInt(id)) {
                shops[index].shop_oauth_param.key_and_token_exist = 1
              }
            })

            this.$router.push({ path: '', query: {}})
            setTimeout(() => {
              this.openNotify('success', 'common_notificationCodeAdded')
            }, 200)

          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })
      },

      checkAddShopPermission() {
        if (this.$route.query.code !== undefined && this.$route.query.state !== undefined) {
          let data = {
            verifier: this.$route.query.code
          }
          let id = this.$route.query.state

          this.savePermission(data, id)
        }

        if (this.$route.query.code !== undefined && this.$route.query.shop !== undefined && localStorage.getItem('shopifyId')) {
          let data = {
            verifier: this.$route.query.code
          }
          let id = localStorage.getItem('shopifyId')

          this.savePermission(data, id)
          localStorage.removeItem('shopifyId')
        }

        if (this.$route.query.state !== undefined && this.$route.query.selling_partner_id !== undefined
            && this.$route.query.spapi_oauth_code !== undefined) {
          let data = {
            mws_auth_token: this.$route.query.mws_auth_token !== undefined ? this.$route.query.mws_auth_token : '',
            state: this.$route.query.state,
            selling_partner_id: this.$route.query.selling_partner_id,
            shop_id: localStorage.getItem('amazonShopId'),
            spapi_oauth_code: this.$route.query.spapi_oauth_code,
          }

          let id = localStorage.getItem('amazonShopId')

          console.log('amazonShopId');
          this.savePermission(data, id, true)
          localStorage.removeItem('amazonShopId')
        }
      },

      downloadFiles(type) {
        let forPage, page, url

        // exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // Generate filter URL
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        if(this.filterName !== '') myQuery.where('shopName', this.filterName)
        if(this.filterUserId !== '') myQuery.where('user', this.filterUserId)
        if(this.filterShopLink !== '') myQuery.where('shop_link', this.filterShopLink)
        if(this.filterShopRefText !== '') myQuery.where('reference_text', this.filterShopRefText)

        console.log(333333333333333);
        // console.log(this.typeShops[0].activeTab);
        // console.log(this.filterShopId);

        if(this.$store.getters.getIsAdminRights === 'admin'){
          myQuery.where('type', this.filterShop)
        }

        if(this.filterShopId !== '' && this.typeShops[0].activeTab === true) {
          myQuery.where('type', this.filterShopId)
        } else {
          this.typeShops.map((item) => {
            if(item.activeTab){
              if(item.shop.id !== 0 && item.shop.name !== this.staticParentShopTypeName){
                myQuery.where('type', item.shop.id)
              } else if (item.shop.name === this.staticParentShopTypeName) {
                myQuery.where('parent_type_id', item.shop.id)
              }
            }
          })
        }

        if(this.filterVerify !== ''){
          myQuery.where('shopStatus', this.filterVerify)
        } else if(this.$store.getters.getIsAdminRights === 'admin'){
          myQuery.where('shopStatus', SHOPS_ADMIN_TYPES.notValid)
        }


        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if(this.filterDate.length > 0){
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'between_start_end',
              date
          )
        }

        url = this.generateGetParamsFromQuery(myQuery, forPage, page)

        this.$store.dispatch('getExportShops', {filter: url , type: type}).then((response) => {
          const blob = new Blob([response.data], { type: `application/${type}` })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Shops.${type}`
          link.click()
          URL.revokeObjectURL(link.href)
        })

      },

    },

  }
</script>

<style lang="scss">

</style>
