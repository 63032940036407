<template>
  <div class="fragment h-100 d-flex direction-column">
    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--xl-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shops_shopID'])"></span>

        <DefaultInput
            class="white"
            v-bind:class="{'ui-no-valid': dataValidate.validation.title}"
            :error="dataValidate.validation.title"
            :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.title : $t(`${dataValidate.validationTranslate.title}.localization_value.value`)"
            :label="$t('shops_shopID.localization_value.value')"
            :type="'text'"
            v-model="data.shopId"
            :disabled="true"
            @input="changeData"
        />
      </div>
    </div>



    <div class="custom-row mt-auto">
      <div class="custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shops_emailPayPalForYour'])"></span>
        <WarningStatic class="mw-100 w-100">
          <template slot="title">
            skladusa.pp@gmail.com
          </template>
          <template slot="text">
            {{$t('shops_emailPayPalForYour.localization_value.value')}}
          </template>
        </WarningStatic>
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import WarningStatic from "../../../../../UI/alerts/WarningStatic/WarningStatic";

  export default {
    name: "EtsyShop",
    components: {
      DefaultInput,
      WarningStatic,
    },

    props:['shopEtsy', 'validate'],

    data(){
      return {
        data: this.shopEtsy,
        dataValidate: this.validate,
      }
    },

    watch: {
      validate(validate) {
        this.dataValidate = validate
      },
    },

    methods: {
      changeData() {
        this.$emit('input', this.data)
      }
    }

  }
</script>

<style lang="scss">

</style>
