<template>
  <ShopsEditingUser />
</template>

<script>
  import ShopsEditingUser from "./ShopsEditingUser/ShopsEditingUser";
  import {shopDetails} from "../../../../../mixins/shopMixins/shopTypeFunc";
  // import {shopDetails} from '../../../../../../../../mixins/mobileFunctions'

  export default {
    name: "ShopsEditing",

    components: {
      ShopsEditingUser,
    },

    mixins: [shopDetails],

    data() {
      return {
      }
    },

    created() {
      this.$store.dispatch('fetchShopsTypes').then(() => {
        this.shopTypeFunc()
      })
    }
  }
</script>

<style scoped>

</style>
