<template>
  <modal
          @close="$emit('close')"
          class=" custom-popup-modal custom-popup active-perm-api"
  >
    <template slot="header">
      <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shops_activatePermissionAPI'])"></span>
        {{$t('shops_activatePermissionForAPI.localization_value.value')}}
      </span>
    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'shops_toGetAnAccessTo',
          'shops_pleaseClick',
          'shops_hereAndGiveA',
          'shops_code',
          'common_cancel'
        ])"></span>
        <div class="custom-popup__txt active-perm-api__txt">
          {{$t('shops_toGetAnAccessTo.localization_value.value')}}
          <a :href="$store.getters.getShopsPermissionData.shopsPermissionLink" target="_blank" class="site-link black">
            {{$t('shops_pleaseClick.localization_value.value')}}
          </a>
          {{$t('shops_hereAndGiveA.localization_value.value')}}
        </div>

        <div class="custom-row">
          <div class="custom-col custom-col--33 custom-col--xs-100">
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.code}"
                    :error="validation.code"
                    :errorTxt="serverError ? validationTxt.code : $t(`${validationTranslate.code}.localization_value.value`)"
                    :type="'email'"
                    :label="$t('shops_code.localization_value.value')"
                    v-model="code"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex mt-5">
        <span
                class="site-link site-link--alt custom-popup__btn-i mr-4"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                class="custom-popup__btn-i wfc"
                :value="$t('shops_sendCode.localization_value.value')"
                @click.native="sendPermissionsCode"
                v-bind:class="{'disabled-btn' : $store.getters.getShopBtn}"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import {validation} from "../../../../../services/validation";

  export default {
    name: "ActivatePermissionAPIPopup",
    components: {
      Modal,
      DefaultInput,
      MainButton,
    },

    data() {
      return {
        code: '',

        serverError: false,

        validation: {
          code: false,
        },

        validationTranslate: {
          code: '',
        },

        validationTxt: {
          code: false,
        },
      }
    },

    methods: {
      sendPermissionsCode() {
        let data = {
          verifier: this.code
        }

        let validationItems = {
          code: this.code,
        };

        let validationOptions = {
          code: {
            type: [
              'empty',
            ]
          },
        };

        let validate = validation(validationItems, validationOptions)
        this.validation = validate.validation
        this.validationTranslate = validate.validationError

        if (validate.isValidate) {

          this.serverError = false

          this.$store.dispatch('saveShopPermission', {data: data}).then((response) => {

            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                let shops = this.$store.getters.getShops
                shops.map((item, index) => {
                  if(item.id === this.$store.getters.getShopsPermissionData.shopId) {
                    shops[index].shop_oauth_param.key_and_token_exist = 1
                  }
                })

                this.$emit('done')
                setTimeout(() => {
                  this.openNotify('success', 'common_notificationCodeAdded')
                }, 200)
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                this.serverError = true;
                this.validation.code = true
                this.validationTxt.code = 'Wrong code'
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }

            // if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            //
            //   let shops = this.$store.getters.getShops
            //   shops.map((item, index) => {
            //     if(item.id === this.$store.getters.getShopsPermissionData.shopId) {
            //       shops[index].shop_oauth_param.key_and_token_exist = 1
            //     }
            //   })
            //
            //   this.$emit('done')
            //   setTimeout(() => {
            //     this.openNotify('success', 'common_notificationCodeAdded')
            //   }, 200)
            //
            // } else if (response.response.status === this.$store.getters.GET_ERRORS.FORBIDDEN_CODE) {
            //
            //   let errors = response.response.data.data.errors;
            //
            //   this.serverError = true;
            //   errors[0] ? (this.validation.code = true, this.validationTxt.code = errors[0]) : false;
            // }
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../../scss/colors";

  .active-perm-api {

    &__txt {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $borderBrown;
      margin-bottom: 32px;

      .site-link {
        font-size: 16px;
      }
    }
  }
</style>
