<template>
  <div class="fragment">

    <template v-if="$store.getters.getShops.length > 0 && $store.getters.getLoadingShops !== true">
      <div class="site-table-wrap shops-admin-table" v-if="!isMobileFunc()"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['shops_created', 'shops_shopName', 'shops_linkToShop', 'shop_type', 'shops_isTradeMark', 'shop_user', 'warehouseManagement_manage'])"></span>

        <table class="site-table">
          <thead>
          <tr>
            <th>{{$t('shops_created.localization_value.value')}}</th>
            <th>{{$t('shops_shopName.localization_value.value')}}</th>
            <th>{{$t('shops_linkToShop.localization_value.value')}}</th>
            <th>{{$t('shop_type.localization_value.value')}}</th>
            <th class="text-center white-space-line">
              {{$t('shops_isTradeMark.localization_value.value')}}
            </th>
            <th>{{$t('shop_user.localization_value.value')}}</th>
            <th>{{$t('common_comment.localization_value.value')}}</th>
            <th class="pr-2">{{$t('warehouseManagement_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(item, index) in $store.getters.getShops"
            :key="index + 'shops'"
          >
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td>
              <div class="shops-admin-table-row">
                {{ item.shop_name }}
              </div>
            </td>
            <td class="white-space-line" style="max-width: 200px">
              <a class="table-link btn-style"
                 v-if="_.has(item.shop_param, 'shop_link')"
                 :href="item.shop_param.shop_link"
                 target="_blank"
              >{{ item.shop_param.shop_link }}</a>
            </td>
            <td>
              <div class="table-row d-flex ">
                <v-popover
                    class="site-tooltip"
                    offset="5"
                    placement="top-left"
                    trigger="hover"
                >
                  <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit" @click="editTranslate(['shops_active', 'shops_activeTxt', 'shops_inProgress', 'shops_inProgressTxt', 'shops_declined', 'shops_declinedTxt'])"></span>
                    <StatusIcoBtn
                        :type="item.shop_status"
                        class="mr-1 mb-1 d-block"
                    />
                  </span>

                  <template slot="popover"
                            v-if="item.shop_status === 'valid'"
                  >
                    <div class="status-tooltip">
                      <StatusIcoBtn :type="item.shop_status" class="mr-2"/>
                      {{$t('shops_active.localization_value.value')}}
                    </div>
                    <p class="mb-0 mt-1">{{$t('shops_activeTxt.localization_value.value')}}</p>
                  </template>
                  <template slot="popover"
                            v-if="item.shop_status === 'not_valid'"
                  >
                    <div class="status-tooltip brown-tooltip">
                      <StatusIcoBtn :type="item.shop_status" class="mr-2"/>
                      {{$t('shops_inProgress.localization_value.value')}}
                    </div>
                    <p class="mb-0 mt-1"> {{$t('shops_inProgressTxt.localization_value.value')}}</p>
                  </template>
                  <template slot="popover"
                            v-if="item.shop_status === 'cancel'"
                  >

                    <div class="status-tooltip brown-tooltip">
                      <StatusIcoBtn :type="item.shop_status" class="mr-2"/>
                      Declined
                    </div>
                    <p class="mb-0 mt-1">This shop was not approved by administrator</p>
                  </template>
                </v-popover>

                <span class="mr-2">
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'nothing_above'">Other</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'shopify'">Shopify</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'raverly'">Raverly</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'tilda'">Tilda</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'woocommerce'">Wooco</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'opencart'">Opencart</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'etsy'">Etsy</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'amazonmws'">Amazon</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'ebay'">Ebay</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'wish'">Wish</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'ukrainiantreasures'">Ukrainiantreasures</span>

                  <!--<img v-if="item.shop_type !== null && item.shop_type.name === 'etsy'"-->
                       <!--src="/img/shops-group/etsy-shop-icon.png" alt="shop-img">-->
                  <!--<img v-if="item.shop_type !== null && item.shop_type.name === 'amazonmws'"-->
                       <!--src="/img/shops-group/amazon-shop-icon.png" alt="shop-img">-->
                  <!--<img v-if="item.shop_type !== null && item.shop_type.name === 'ebay'"-->
                       <!--src="/img/shops-group/ebay-shop-icon.png" alt="shop-img">-->
                </span>


                <div class="case-cart__content status--inactive"
                     v-if="item.shop_oauth_param.key_and_token_exist !== 0"
                     @click="removePermission(item.id)">
                  <!--{{$t('shops_deletePermission.localization_value.value')}}-->
                  <StatusIcoBtn
                    class="delete-permission-ico"
                    :type="'cancel-red'"
                  />
                </div>

              </div>
            </td>
            <td align="center">
              <StatusIcoBtn
                  v-if="item.shop_param.is_trade_mark === '1'"
                  :type="'delivered'"
              />
              <StatusIcoBtn
                  v-else
                  :type="'cancel'"
              />
            </td>
            <td>
              <TableUserColumn
                      :item="item"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'status_commentary.comment'"
              />
            </td>

            <td class="pr-2">
              <div class="table-right table-manage-list">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"
                  class="manager-btn--shop-admin mb-1"
                >

                  <template v-if="item.shop_status === 'valid' && item.shop_type.name === 'ukrainiantreasures' &&
                    _.has(currentPermissions, PERMISSIONS.UKRAINIAN_TREASURES_SETTINGS)" slot="item">
                    <LinkButton
                        :value="$t('shops_settings.localization_value.value')"
                        :type="'edit'"
                        @click.native="editShopMkSettings(item.id)"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                        :value="$t('common_edit.localization_value.value')"
                        :type="'edit'"
                        @click.native="editShop(item.id)"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="deleteShop(item.id, item)"
                    />
                  </template>
                </ManagerButton>

                <div class="table-btn ml-auto d-flex"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['shops_accept', 'shops_decline'])"></span>
                  <template v-for="(itemTab, index) in adminTabs">
                    <MainButton
                            :value="$t('shops_accept.localization_value.value')"
                            @click.native="updateShopsStatusAccept(item.id)"
                            :key="index + 'shops_accept'"
                            v-if="itemTab.active && itemTab.tabType === SHOPS_ADMIN_TYPES.notValid"
                    />
                    <MainButton
                            class="ml-2"
                            :value="$t('shops_decline.localization_value.value')"
                            @click.native="updateShopsStatusDeclined(item.id)"
                            :key="index + 'shops_decline'"
                            v-if="itemTab.active && (itemTab.tabType === SHOPS_ADMIN_TYPES.valid || itemTab.tabType === SHOPS_ADMIN_TYPES.notValid)"
                    />

                    <MainButton
                            :value="$t('shops_accept.localization_value.value')"
                            @click.native="updateShopsStatusAccept(item.id)"
                            :key="index + 'shops_accept2'"
                            v-if="itemTab.active && itemTab.tabType === SHOPS_ADMIN_TYPES.cancel"
                    />
                  </template>

                </div>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

      </div>


      <div class="table-card" v-else>
        <div class="table-card__list">

          <div class="table-card__item"
             v-for="(item, index) in $store.getters.getShops"
             :key="index"
          >
            <ShopsTableMobile
                    :item="item"
                    :adminTabs="adminTabs"
                    @updateShopsStatusAccept="updateShopsStatusAccept"
                    @updateShopsStatusDeclined="updateShopsStatusDeclined"
                    @removePermission="removePermission"
                    @editShop="editShop(item.id)"
                    @editShopMkSettings="editShopMkSettings(item.id)"
                    @deleteShop="deleteShop(item.id, item)"
            />
          </div>
        </div>
      </div>

      <div class="table-bottom-btn" >
        <div class="table-bottom-btn__inner">
          <ShowMore
                    v-if="$store.getters.getShopsCommonList.next_page_url !== null && $store.getters.getShops.length > 0"
                    @click.native="$emit('showMore')"
                    :count="$store.getters.getShopsCommonList.total - $store.getters.getShopsForPage * $store.getters.getShopsCommonList.current_page < $store.getters.getShopsForPage ?
                  $store.getters.getShopsCommonList.total - $store.getters.getShopsForPage * $store.getters.getShopsCommonList.current_page:
                  $store.getters.getShopsForPage"
          />
          <ExportBtn
              @downloadFiles="type => $emit('downloadFiles', type)"
              class="table-bottom-btn__right"/>
        </div>
      </div>
    </template>


    <template v-if="$store.getters.getLoadingShops === false && $store.getters.getShops.length === 0">
      <NoResult
        :countFilterParams="countFilterParams"
        :title="$t('common_noDataYet.localization_value.value')"
        :titleWithFilter="$t('common_noResult.localization_value.value')"
        :text="$t('common_noDataYetTxt.localization_value.value')"
        :textWithFilter="$t('common_noResultSeems.localization_value.value')"
        @resetFilter="$emit('resetFilter')"
      />
    </template>

    <CommentsPopup
      v-if="isModalCommentsPopup"
      @close="closeCommentsPopup"
    />

    <SendEmailPopup
      v-if="isModalSendEmailPopup"
      @close="closeSendEmailPopup"
    />

    <LastFiveIPPopup
      v-if="isModalLastFiveIPPopup"
      @close="closeLastFiveIPPopup"
    />

    <StatisticPopup
      v-if="isModalStatisticPopup"
      @close="closeStatisticPopup"
    />

  </div>
</template>

<script>
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import CommentsPopup from "../../../../../../coreComponents/TableComponents/TableUserColumn/popups/CommentsPopup/CommentsPopup";
  import SendEmailPopup from "../../../../popups/SendEmailPopup/SendEmailPopup";
  import LastFiveIPPopup from "../../../../../../coreComponents/TableComponents/TableUserColumn/popups/LastFiveIPPopup/LastFiveIPPopup";
  import StatisticPopup from "../../../../../../coreComponents/TableComponents/TableUserColumn/popups/StatisticPopup/StatisticPopup";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ShopsTableMobile from "./ShopsTableMobile/ShopsTableMobile";
  import {SHOPS_ADMIN_TYPES} from "../../../../../../../staticData/staticVariables";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "ShopsTable",
    components: {
      ValueHelper,
      TableUserColumn,
      StatusIcoBtn,
      ManagerButton,
      LinkButton,
      ShowMore,
      MainButton,
      CommentsPopup,
      SendEmailPopup,
      LastFiveIPPopup,
      StatisticPopup,
      NoResult,
      ShopsTableMobile,
      ExportBtn,
    },

    mixins: [mixinDetictingMobile],

    props: ['countFilterParams' ,'adminTabs'],

    data() {
      return {
        show1: false,
        show2: false,

        SHOPS_ADMIN_TYPES: SHOPS_ADMIN_TYPES,

        isModalCommentsPopup: false,
        isModalSendEmailPopup: false,
        isModalLastFiveIPPopup: false,
        isModalStatisticPopup: false,

        shopsTypes: this.$store.getters.getDefaultShopsTypes,
      }
    },

    methods:{
      showCommentsPopup(){
        this.isModalCommentsPopup = true
      },

      closeCommentsPopup(){
        this.isModalCommentsPopup = false
      },

      showSendEmailPopup(){
        this.isModalSendEmailPopup= true
      },

      closeSendEmailPopup(){
        this.isModalSendEmailPopup= false
      },

      showLastFiveIPPopup(){
        this.isModalLastFiveIPPopup= true
      },

      closeLastFiveIPPopup(){
        this.isModalLastFiveIPPopup= false
      },
      showStatisticPopup(){
        this.isModalStatisticPopup= true
      },

      closeStatisticPopup(){
        this.isModalStatisticPopup= false
      },

      editShop(id) {
        this.$router.push(this.$store.getters.GET_PATHS.mainSettingsShops + '/' + id)
      },

      editShopMkSettings(id) {
        this.$router.push(this.$store.getters.GET_PATHS.marketplaceSettingsEditing + '/' + id)
      },

      deleteShop(id, item) {

        let text = {
          title: 'shops_deleteShop',
          txt: 'shops_deleteShopQuest',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        if(item.shop_type.name === this.shopsTypes.ukrainiantreasures.type && !this._.find(this.adminTabs, {tabType: 'not_valid'}).active) {
          text = {
            title: 'shops_deleteShop',
            txt: 'shops_deleteShopQuestUkrainian',
            yes: 'common_confirmDelete',
            no: 'common_confirmNo'
          }
        }

        let confirm = () => {
          this.$store.dispatch('deleteShops', id).then((response) => {

            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let shops = this.$store.getters.getShops
              shops.map((item, index) => {
                if(item.id === id) {
                  shops.splice(index, 1)
                }
              })
              setTimeout(() => {
                this.openNotify('success', 'common_notificationStoreDeleted')
              }, 200)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      removePermission(id) {

        let text = {
          title: 'shops_deleteShopPermission',
          txt: 'shops_deleteShopPermissionQuest',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteShopPermission', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let shops = this.$store.getters.getShops
              shops.map((item, index) => {
                if(item.id === id) {
                  shops[index].shop_oauth_param.key_and_token_exist = 0
                }
              })
              setTimeout(() => {
                this.openNotify('success', 'common_notificationPermissionRemoved')
              }, 200)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      updateShopsStatusAccept(id) {
        let text = {
          title: 'shops_changeShopStatus',
          txt: 'shops_changeShopStatusTxt',
          yes: 'common_update',
          no: 'common_confirmNo',
        }

        let confirm = () => {

          this.$store.dispatch('updateShopsStatus', {id: id, data: {shopStatus: SHOPS_ADMIN_TYPES.valid, comment: ''}}).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                let shops = this.$store.getters.getShops
                shops.map((item, index) => {
                  if(item.id === id) {
                    shops.splice(index, 1)
                  }
                })

                this.openNotify('success', 'common_notificationStatusChanged')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      updateShopsStatusDeclined(id) {

        let text = {
          title: 'shops_changeShopStatus',
          txt: 'shops_changeShopStatusTxt',
          yes: 'common_update',
          no: 'common_confirmNo',
          commentTranslate: 'common_comment',
        }

        let confirm = (comment) => {

          this.$store.dispatch('updateShopsStatus', {id: id, data: {shopStatus: SHOPS_ADMIN_TYPES.cancel, comment: comment}})
              .then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let shops = this.$store.getters.getShops
              shops.map((item, index) => {
                if(item.id === id) {
                  shops.splice(index, 1)
                }
              })

              setTimeout(() => {
                this.openNotify('success', 'common_notificationStatusChanged')
              }, 200)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm, false, true)
      },


    }
  }
</script>

<style scoped lang="scss">
  .shops-admin-table-row{
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .shops-admin-user-table{
    max-width: 135px;

    @media (max-width: 1600px) {
      max-width: 115px;
    }
  }

  .delete-permission-ico{
    width: 20px;
    top: 0px;
  }

</style>
