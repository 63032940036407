<template>
  <div class="shops-table">
    <ShopsHead
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @changeFilter="changeFilter"
            :showFilter="showFilter"
            :typeShops="dataTypeShops"
            @changeShopType="changeShopType"
            :countFilterParams="countFilterParams"
    />
    <div class="table-filter-wrap">

      <ShopsFilter
          :showFilter="showFilter"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
          :filterGetParams="filterGetParams"
          :typeShopsFilter="typeShopsFilter"
          :typeShops="typeShops"
      />

      <ShopsTable
              :countFilterParams="countFilterParams"
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              @reload="$emit('reload')"
              @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>

  </div>
</template>

<script>
  import ShopsHead from "./ShopsHead/ShopsHead.vue";
  import ShopsFilter from "./ShopsFilter/ShopsFilter.vue";
  import ShopsTable from "./ShopsTable/ShopsTable.vue";

  export default {
    name: "ShopsTablePageUser",
    components: {
      ShopsHead,
      ShopsFilter,
      ShopsTable,
    },

    props: [
      'typeShops',
      'countFilterParams',
      'filterGetParams',
      'typeShopsFilter',
    ],

    watch: {
      typeShops(newVal) {
        this.dataTypeShops = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
    },

    data() {
      return {
        dataTypeShops: this.typeShops,
        showFilter: false,
        // filterId: '',
        // filterName: '',
        // forPage: 10,
        // page: 1,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },

      changeFilter(data) {
        this.$emit('changeFilter', data)
      },
    }
  }
</script>

<style scoped>

.shops-table >>> .case-cart__title {
  max-width: 90%;
}

</style>
