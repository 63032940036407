<template>
  <div class="fragment shops-page-table__fragment">
    <div class="custom-row shops-page-table">


      <template v-if="$store.getters.getShops.length > 0 && $store.getters.getLoadingShops !== true">
        <div class="shops-page-table__col custom-col custom-col--20 custom-col--xs-100 custom-col--sm-50 custom-col--lg-33 custom-col--xl-25"
           v-for="(item, index) in $store.getters.getShops"
           :key="index"
      >
        <div class="case-cart">
          <div class="case-cart__inner">
            <div class="case-cart__status"
                 v-if="item.shop_oauth_param.key_and_token_exist === 1 && item.shop_type.id === SHOP_TYPES.etsy.id && item.shop_param.logo_url">
              <div class="shop-img">
                <img :src="item.shop_param.logo_url" alt="img">
              </div>
            </div>
            <div class="case-cart__label-top"
                 v-if="item.shop_g_a_params && item.shop_g_a_params.ga_shop_default === 1"
            >
              <div class="case-cart__label-top-i"></div>
            </div>
            <div class="case-cart__head">
              <div class="case-cart__title">
                {{ item.shop_name }}
              </div>
              <div class="case-cart__shop">
                <div class="case-cart__shop-status">
                  <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          trigger="hover"
                  >

                      <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <span class="admin-edit" @click="editTranslate(['shops_active', 'shops_activeTxt', 'shops_inProgress', 'shops_inProgressTxt', 'shops_declined', 'shops_declinedTxt'])"></span>

                      <StatusIcoBtn :type="item.shop_status"
                                    class="mr-1"/>
                      </span>
                      <template slot="popover"
                                v-if="item.shop_status === 'valid'"
                      >
                        <div class="status-tooltip brown-tooltip">
                          <StatusIcoBtn :type="item.shop_status" class="mr-2"/>
                          {{$t('shops_active.localization_value.value')}}
                        </div>
                        <p class="mb-0 mt-1">
                          {{$t('shops_activeTxt.localization_value.value')}}
                        </p>
                      </template>


                      <template slot="popover"
                                v-if="item.shop_status === 'not_valid'"
                      >
                        <div class="status-tooltip brown-tooltip">
                          <StatusIcoBtn :type="item.shop_status" class="mr-2"/>
                          {{$t('shops_inProgress.localization_value.value')}}
                        </div>
                        <p class="mb-0 mt-1">
                          {{$t('shops_inProgressTxt.localization_value.value')}}
                        </p>

                      </template>
                      <template slot="popover"
                                v-if="item.shop_status === 'cancel'"
                      >
                        <div class="status-tooltip brown-tooltip">
                          <StatusIcoBtn :type="item.shop_status" class="mr-2"/>
                          {{$t('shops_declined.localization_value.value')}}
                        </div>
                        <p class="mb-0 mt-1">
                          {{$t('shops_declinedTxt.localization_value.value')}}
                        </p>
                        <p class="mb-0 mt-1">
                          <b>{{$t('common_comment.localization_value.value')}}:</b>
                          <ValueHelper
                              v-if="item.status_commentary"
                              :value="item"
                              :deep="'status_commentary.comment'"
                          />
                        </p>
                      </template>
                  </v-popover>
                </div>
                <div class="case-cart__shop-type-img">
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'nothing_above'">Other</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'shopify'">Shopify</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'raverly'">Raverly</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'tilda'">Tilda</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'woocommerce'">Woocommerce</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'opencart'">Opencart</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'amazonmws'">Amazon MWS</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'etsy'">Etsy</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'amazonmws'">Amazon</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'ebay'">Ebay</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'wish'">Wish</span>
                  <span v-if="item.shop_type !== null && item.shop_type.name === 'ukrainiantreasures'"><ValueHelper :value="item" :deep="'shop_type.currentTranslate.label'"/></span>
                  <!--<img v-if="item.shop_type !== null && item.shop_type.name === 'etsy'"-->
                       <!--src="/img/shops-group/etsy-shop-icon.png" alt="shop-img">-->
                  <!--<img v-if="item.shop_type !== null && item.shop_type.name === 'amazonmws'"-->
                       <!--src="/img/shops-group/amazon-shop-icon.png" alt="shop-img">-->
                  <!--<img v-if="item.shop_type !== null && item.shop_type.name === 'ebay'"-->
                       <!--src="/img/shops-group/ebay-shop-icon.png" alt="shop-img">-->
                </div>
                <div v-if="item.shop_g_a_params && item.shop_g_a_params.is_ga_view_key === 1" class="case-cart__shop-analytics">
                  <img src="/img/shops-group/shop-analytics-icon.svg" alt="ico" width="16" height="16">
                </div>
              </div>
            </div>
            <div class="case-cart__body"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit"
                    @click="editTranslate(['shops_created', 'shops_permisiion', 'shops_getAPIPermission', 'shops_getAPIPermission', 'shops_deletePermission'])"></span>

              <div class="case-cart__row custom-row">
                <div class="case-cart__col custom-col custom-col--50">
                  <div class="case-cart__label">
                    {{$t('shops_created.localization_value.value')}}
                  </div>
                  <div class="case-cart__content">
                    {{ item.created_at | moment("DD MMM, YYYY") }}
                  </div>
                </div>
                <div class="case-cart__col custom-col custom-col--50"
                     v-if="item.shop_type !== null && item.shop_type.need_oauth_button === 1">
                  <div class="case-cart__label">
                    {{item.shop_type.currentTranslate.label}}
                    {{$t('shops_permisiion.localization_value.value')}}
                  </div>
                  <div class="case-cart__content status--active" v-if="item.shop_oauth_param.key_and_token_exist === 0"
                       @click="showActivatePermission(item.id, item.shop_type)">
                    {{$t('shops_getAPIPermission.localization_value.value')}}
                  </div>
                  <div class="case-cart__content status--inactive"
                       v-else
                       @click="removePermission(item.id)">
                    {{$t('shops_deletePermission.localization_value.value')}}
                  </div>
                </div>
              </div>
              <div class="case-cart__row custom-row">
                <div class="case-cart__col custom-col">
                  <div class="case-cart__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['shops_linkToTheShop'])"></span>
                    {{$t('shops_linkToTheShop.localization_value.value')}}
                  </div>
                  <div class="case-cart__content white-space-line">
<!--                    {{ item.shop_param.shop_link }}-->
                    <a class="table-link btn-style" :href="item.shop_param.shop_link" target="_blank">{{ item.shop_param.shop_link }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="case-cart__bottom">
              <div class="case-cart__btn ml-auto"
                   v-if="item.shop_oauth_param.key_and_token_exist === 1 && item.shop_type.id === SHOP_TYPES.etsy.id"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit"
                      @click="editTranslate(['shops_updateFromEtsy'])"></span>
                <CaseCartButton
                        class="secondary-brown"
                        :value="$t('shops_updateFromEtsy.localization_value.value')"
                        @click.native="updateFromEtsy(item.id)"
                >
                </CaseCartButton>
              </div>
              <div class="case-cart__btn ml-auto"
                   v-if="item.shop_status === 'valid' && item.shop_type.id === SHOP_TYPES.ukrainiantreasures.id &&
                    _.has(currentPermissions, PERMISSIONS.UKRAINIAN_TREASURES_SETTINGS)"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit"
                      @click="editTranslate(['shops_settings'])"></span>
                <router-link :to="$store.getters.GET_PATHS.marketplaceSettingsEditing + '/' + item.id">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="$t('shops_settings.localization_value.value')"
                  >
                  </CaseCartButton>
                </router-link>
              </div>

              <div class="case-cart__btn ml-auto"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit"
                      @click="editTranslate(['common_edit'])"></span>
                <router-link :to="$store.getters.GET_PATHS.mainSettingsShops + '/' + item.id">
                  <CaseCartButton
                          :value="$t('common_edit.localization_value.value')"
                          :ico="true"
                  >
                    <template slot="ico">
                      <EditIconWhite/>
                    </template>
                  </CaseCartButton>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      </template>

      <!--<template v-if="$store.getters.getLoadingShops === true">-->
        <!--<div class="empty-list "-->
             <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin}">-->
          <!--<span class="admin-edit" @click="editTranslate(['common_loading'])"></span>-->
          <!--{{ $t('common_loading.localization_value.value') }}-->
        <!--</div>-->
      <!--</template>-->




      <template v-if="$store.getters.getLoadingShops === false && $store.getters.getShops.length === 0">
        <NoResult
            :countFilterParams="countFilterParams"
            :title="$t('common_noDataYet.localization_value.value')"
            :titleWithFilter="$t('common_noResult.localization_value.value')"
            :text="$t('common_noDataYetTxt.localization_value.value')"
            :textWithFilter="$t('common_noResultSeems.localization_value.value')"
            @resetFilter="$emit('resetFilter')"
        />

<!--        <div class="empty-list "-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin,-->
<!--             'no-data' : countFilterParams > 0}">-->
<!--          <span class="admin-edit" @click="editTranslate(['common_noResult', 'common_noDataYet', 'common_noResultSeems', 'common_noDataYetTxt', 'common_resetFilters'])"></span>-->
<!--          &lt;!&ndash;{{ $t('notification_noSearchResults.localization_value.value') }}&ndash;&gt;-->

<!--          <div class="empty-list__title" v-if="countFilterParams > 0">-->
<!--            {{$t('common_noResult.localization_value.value')}}-->
<!--          </div>-->
<!--          <div class="empty-list__title" v-else>-->
<!--            {{$t('common_noDataYet.localization_value.value')}}-->
<!--          </div>-->

<!--          <div class="empty-list__txt" v-if="countFilterParams > 0" v-html="$t('common_noResultSeems.localization_value.value')">-->
<!--          </div>-->
<!--          <div class="empty-list__txt" v-else v-html="$t('common_noDataYetTxt.localization_value.value')">-->
<!--          </div>-->

<!--          <div class="empty-list__btn" v-if="countFilterParams > 0">-->
<!--            <MainButton-->
<!--              class="secondary btn-fit-content"-->
<!--              :value="$t('common_resetFilters.localization_value.value')"-->
<!--              @click.native="$emit('resetFilter')"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
      </template>


      <!--<div class="shops-page-table__col custom-col custom-col&#45;&#45;20 custom-col&#45;&#45;xs-100 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;lg-33 custom-col&#45;&#45;xl-25"-->
      <!--v-for="(item, index) in list"-->
      <!--:key="index"-->
      <!--&gt;-->
      <!--<div class="case-cart">-->
      <!--<div class="case-cart__inner">-->
      <!--<div class="case-cart__label-top"-->
      <!--v-if="item.label"-->
      <!--&gt;-->
      <!--<div class="case-cart__label-top-i"></div>-->
      <!--</div>-->
      <!--<div class="case-cart__head">-->
      <!--<div class="case-cart__title">-->
      <!--{{ item.nameShop }}-->
      <!--</div>-->
      <!--<div class="case-cart__shop">-->
      <!--<div class="case-cart__shop-status">-->
      <!--<v-popover-->
      <!--class="site-tooltip"-->
      <!--offset="5"-->
      <!--placement="top-left"-->
      <!--trigger="hover"-->
      <!--&gt;-->
      <!--<span>-->
      <!--<StatusIcoBtn :type="item.status" class="mr-1" />-->
      <!--</span>-->
      <!--<template slot="popover"-->
      <!--v-if="item.status == 'active'"-->
      <!--&gt;-->
      <!--<div class="status-tooltip brown-tooltip">-->
      <!--<StatusIcoBtn :type="item.status" class="mr-2" /> Active-->
      <!--</div>-->
      <!--<p class="mb-0 mt-1">This shop is activated by administrator</p>-->
      <!--</template>-->
      <!--<template slot="popover"-->
      <!--v-if="item.status == 'declined'"-->
      <!--&gt;-->
      <!--<div class="status-tooltip brown-tooltip">-->
      <!--<StatusIcoBtn :type="item.status" class="mr-2" /> Declined-->
      <!--</div>-->
      <!--<p class="mb-0 mt-1">This shop was not approved by administrator</p>-->
      <!--</template>-->
      <!--<template slot="popover"-->
      <!--v-if="item.status == 'in-progress'"-->
      <!--&gt;-->
      <!--<div class="status-tooltip brown-tooltip">-->
      <!--<StatusIcoBtn :type="item.status" class="mr-2" /> In progress-->
      <!--</div>-->
      <!--<p class="mb-0 mt-1">This shop is under consideration</p>-->
      <!--</template>-->
      <!--</v-popover>-->
      <!--</div>-->
      <!--<div class="case-cart__shop-type-img">-->
      <!--<img v-if="item.shopImage == 'etsy'" src="/img/shops-group/etsy-shop-icon.png" alt="shop-img">-->
      <!--<img v-if="item.shopImage == 'amazon'" src="/img/shops-group/amazon-shop-icon.png" alt="shop-img">-->
      <!--<img v-if="item.shopImage == 'ebay'" src="/img/shops-group/ebay-shop-icon.png" alt="shop-img">-->
      <!--</div>-->
      <!--<div v-if="item.shopAnalytics" class="case-cart__shop-analytics">-->
      <!--<img src="/img/shops-group/shop-analytics-icon.svg" alt="ico" width="16" height="16">-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div class="case-cart__body">-->
      <!--<div class="case-cart__row custom-row">-->
      <!--<div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
      <!--<div class="case-cart__label">-->
      <!--Created-->
      <!--</div>-->
      <!--<div class="case-cart__content">-->
      <!--{{ item.created }}-->
      <!--</div>-->
      <!--</div>-->
      <!--<div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
      <!--<div class="case-cart__label">-->
      <!--Etsy Permisiion-->
      <!--</div>-->
      <!--<div class="case-cart__content"-->
      <!--:class="{ 'status&#45;&#45;active' : item.permissionStatus == 'active',-->
      <!--'status&#45;&#45;inactive' : item.permissionStatus == 'inactive'}"-->
      <!--&gt;-->
      <!--{{ item.permissionTxt }}-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div class="case-cart__row custom-row">-->
      <!--<div class="case-cart__col custom-col">-->
      <!--<div class="case-cart__label">-->
      <!--Link to the Shop-->
      <!--</div>-->
      <!--<div class="case-cart__content white-space-line">-->
      <!--{{ item.linkToTheShop }}-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div class="case-cart__bottom">-->
      <!--<div class="case-cart__btn ml-auto">-->
      <!--<CaseCartButton-->
      <!--:value="'Edit'"-->
      <!--:ico="true"-->
      <!--@click.native="showActivatePermission"-->
      <!--&gt;-->
      <!--<template slot="ico">-->
      <!--<EditIconWhite/>-->
      <!--</template>-->
      <!--</CaseCartButton>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->


    </div>

    <div class="table-bottom-btn" v-if="$store.getters.getShopsCommonList.next_page_url !== null">
      <div class="table-bottom-btn__inner">
        <ShowMore class="table-bottom-btn__left"
                  v-if="$store.getters.getShops.length > 0"
                  @click.native="$emit('showMore')"
                  :count="$store.getters.getShopsCommonList.total - $store.getters.getShopsForPage * $store.getters.getShopsCommonList.current_page < $store.getters.getShopsForPage ?
                  $store.getters.getShopsCommonList.total - $store.getters.getShopsForPage * $store.getters.getShopsCommonList.current_page:
                  $store.getters.getShopsForPage"
        />

        <ExportBtn
            @downloadFiles="type => $emit('downloadFiles', type)"
            class="table-bottom-btn__right"/>

      </div>
    </div>


    <ActivatePermissionAPIPopup
            @close="closeActivatePermission"
            v-if="isModalActivatePermission"
    />


    <div class="shops-page-table__notification"
         v-if="$store.getters.getShops.length > 0"
    >
      <WarningStatic class="mw-100">
        <template slot="title">
          skladusa.pp@gmail.com
        </template>
        <template slot="text">
          <span
                  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_emailPayPalForYour'])"></span>
            {{ $t('shops_emailPayPalForYour.localization_value.value') }}
          </span>

        </template>
      </WarningStatic>
    </div>
  </div>
</template>

<script>
  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import EditIconWhite from "../../../../../../../../public/img/UI-group/edit-icon-white_.svg?inline"
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ActivatePermissionAPIPopup from "../../../../popups/ActivatePermissionAPIPopup/ActivatePermissionAPIPopup";
  import WarningStatic from "../../../../../../UI/alerts/WarningStatic/WarningStatic";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "@/components/coreComponents/NoResult/NoResult";
  import {SHOP_TYPES} from "../../../../../../../staticData/staticVariables";
  import ExportBtn from "@/components/coreComponents/Buttons/Export/Export";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "ShopsTable",
    components: {
      ValueHelper,
      CaseCartButton,
      EditIconWhite,
      StatusIcoBtn,
      ActivatePermissionAPIPopup,
      WarningStatic,
      ShowMore,
      ExportBtn,
      NoResult,
    },

    props: ['countFilterParams'],

    data() {
      return {
        isModalActivatePermission: false,

        SHOP_TYPES: SHOP_TYPES,

        list: [
          {
            nameShop: 'Name of the Shop',
            label: true,
            status: 'active',
            shopImage: 'etsy',
            shopAnalytics: true,

            created: '10 Oct, 2020',
            permissionStatus: 'inactive',
            permissionTxt: 'Delete permission',
            linkToTheShop: 'www.etsy/product_name_here__long-long-link',
          },
          {
            nameShop: 'Name of the Shop',
            label: true,
            status: 'declined',
            shopImage: 'amazon',
            shopAnalytics: false,

            created: '10 Oct, 2020',
            permissionStatus: 'active',
            permissionTxt: 'Get API Permission',
            linkToTheShop: 'www.etsy/product_name_here__long-long-link',
          },
          {
            nameShop: 'Name of the Shop',
            label: true,
            status: 'in-progress',
            shopImage: 'ebay',
            shopAnalytics: true,

            created: '10 Oct, 2020',
            permissionStatus: 'active',
            permissionTxt: 'Get API Permission',
            linkToTheShop: 'www.etsy/product_name_here__long-long-link',
          },
          {
            nameShop: 'Name of the Shop',
            label: false,
            status: 'active',
            shopImage: 'etsy',
            shopAnalytics: false,

            created: '10 Oct, 2020',
            permissionStatus: 'active',
            permissionTxt: 'Get API Permission',
            linkToTheShop: 'www.etsy/product_name_here__long-long-link',
          },
          {
            nameShop: 'Name of the Shop',
            label: false,
            status: 'active',
            shopImage: 'etsy',
            shopAnalytics: false,

            created: '10 Oct, 2020',
            permissionStatus: 'active',
            permissionTxt: 'Get API Permission',
            linkToTheShop: 'www.etsy/product_name_here__long-long-link',
          },
        ]
      }
    },

    mounted() {

    },

    methods: {

      updateFromEtsy(id) {
        this.$store.dispatch('updateEtsyShopInfo', {shop_id: id}).then(response => {
          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('reload')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      showActivatePermission(id, type) {
        let defaultTypes = this.$store.getters.getDefaultShopsTypes

        this.$store.dispatch('getShopPermission', id).then((response) => {
          if(defaultTypes.ebay.type === type.name){
            window.location.href = response.data.data.shop_permission_link
          }
          if(defaultTypes.etsy.type === type.name){
            window.location.href = response.data.data.shop_permission_link
            // this.isModalActivatePermission = true
          }
          if(defaultTypes.shopify.type === type.name){
            localStorage.setItem('shopifyId', id)
            window.location.href = response.data.data.shop_permission_link
          }
          if (defaultTypes.amazonmws.type === type.name) {
            localStorage.setItem('amazonShopId', id)
            window.location.href = response.data.data.shop_permission_link
          }

        })
      },

      closeActivatePermission() {
        this.isModalActivatePermission = false
      },

      removePermission(id) {

        let text = {
          title: 'shops_deleteShopPermission',
          txt: 'shops_deleteShopPermissionQuest',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteShopPermission', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let shops = this.$store.getters.getShops
              shops.map((item, index) => {
                if(item.id === id) {
                  shops[index].shop_oauth_param.key_and_token_exist = 0
                }
              })
              setTimeout(() => {
                this.openNotify('success', 'common_notificationPermissionRemoved')
              }, 200)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      }
    }


  }
</script>

<style lang="scss">

  @import "ShopsTable";


</style>
