<template>
  <ShopsCreationUser />
</template>

<script>
  import ShopsCreationUser from "./ShopsCreationUser/ShopsCreationUser";
  import {shopDetails} from "../../../../../mixins/shopMixins/shopTypeFunc";
  // import {shopDetails} from '../../../../../../../../mixins/mobileFunctions'

  export default {
    name: "ShopsCreation",

    components: {
      ShopsCreationUser,
    },

    mixins: [shopDetails],

    data() {
      return {
      }
    },

    created() {
      this.$store.commit('setShopTypesParent', [])
      this.$store.dispatch('fetchShopsTypes').then(() => {
        this.shopTypeFunc()
      })
    }
  }
</script>

<style scoped>

</style>
