<template>
  <div class="fragment h-100 d-flex direction-column">
    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--xl-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shops_shopType'])"></span>
        <DefaultSelect
                class="white"
                :options="$store.getters.getShopTypesChild"
                :optionsLabel="'label'"
                :label="$t('shops_shopType.localization_value.value')"
                :selected="_.find($store.getters.getShopTypesChild, {'id': data.shopChild.id})"
                @change="changeShopType"
        />
      </div>

      <div class="custom-col--50" v-if="defaultShops.opencart !== data.shopChild.name && defaultShops.woocommerce !== data.shopChild.name"></div>

      <template v-if="data.shopChild !== ''">

        <template
                v-if="defaultShops.shopify === data.shopChild.name  ||
                defaultShops.nothingAbove === data.shopChild.name"
        >
          <div class="custom-col custom-col--50 custom-col--xl-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_login'])"></span>
            <DefaultInput
                    class="white"
                    v-bind:class="{'ui-no-valid': dataValidate.validation.shopLogin}"
                    :error="dataValidate.validation.shopLogin"
                    :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.shopLogin : $t(`${dataValidate.validationTranslate.shopLogin}.localization_value.value`)"
                    :type="'text'"
                    :label="$t('shops_login.localization_value.value')"
                    v-model="data.shopLogin"
                    @input="changeData"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--xl-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['login_password'])"></span>
            <DefaultInput
                    class="white"
                    v-bind:class="{'ui-no-valid': dataValidate.validation.shopPassword}"
                    :error="dataValidate.validation.shopPassword"
                    :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.shopPassword : $t(`${dataValidate.validationTranslate.shopPassword}.localization_value.value`)"
                    :type="'text'"
                    :label="$t('login_password.localization_value.value')"
                    v-model="data.shopPassword"
                    @input="changeData"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--xl-100"
               v-if="defaultShops.shopify === data.shopChild.name"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_referenceText'])"></span>
            <DefaultInput
                class="white"
                v-bind:class="{'ui-no-valid': dataValidate.validation.referenceText}"
                :error="dataValidate.validation.referenceText"
                :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.referenceText : $t(`${dataValidate.validationTranslate.referenceText}.localization_value.value`)"
                :type="'text'"
                :label="$t('shops_referenceText.localization_value.value')"
                v-model="data.referenceText"
                @input="changeData"
            />
          </div>
        </template>


        <template v-if="defaultShops.opencart === data.shopChild.name || defaultShops.woocommerce === data.shopChild.name">
          <div class="custom-col custom-col--50 custom-col--xl-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_remoteShop'])"></span>
            <DefaultInput
                    class="white"
                    v-bind:class="{'ui-no-valid': dataValidate.validation.shopApiUrl}"
                    :error="dataValidate.validation.shopApiUrl"
                    :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.shopApiUrl : $t(`${dataValidate.validationTranslate.shopApiUrl}.localization_value.value`)"
                    :type="'text'"
                    :label="$t('shops_remoteShop.localization_value.value')"
                    v-model="data.shopApiUrl"
                    @input="changeData"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--xl-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_remoteShopAPIKey'])"></span>
            <DefaultInput
                    class="white"
                    v-bind:class="{'ui-no-valid': dataValidate.validation.shopApiKey}"
                    :error="dataValidate.validation.shopApiKey"
                    :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.shopApiKey : $t(`${dataValidate.validationTranslate.shopApiKey}.localization_value.value`)"
                    :type="'text'"
                    :label="$t('shops_remoteShopAPIKey.localization_value.value')"
                    v-model="data.shopApiKey"
                    @input="changeData"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--xl-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_remoteShopSecret'])"></span>
            <DefaultInput
                    class="white"
                    v-bind:class="{'ui-no-valid': dataValidate.validation.shopApiSecret}"
                    :error="dataValidate.validation.shopApiSecret"
                    :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.shopApiSecret : $t(`${dataValidate.validationTranslate.shopApiSecret}.localization_value.value`)"
                    :type="'text'"
                    :label="$t('shops_remoteShopSecret.localization_value.value')"
                    v-model="data.shopApiSecret"
                    @input="changeData"
            />
          </div>
        </template>

        <template
            v-if="defaultShops.raverly === data.shopChild.name"
        >
          <div class="custom-col custom-col--50 custom-col--xl-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_ravenflyShopName'])"></span>
            <DefaultInput
                class="white"
                v-bind:class="{'ui-no-valid': dataValidate.validation.ravenflyShopName}"
                :error="dataValidate.validation.ravenflyShopName"
                :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.ravenflyShopName : $t(`${dataValidate.validationTranslate.ravenflyShopName}.localization_value.value`)"
                :type="'text'"
                :label="$t('shops_ravenflyShopName.localization_value.value')"
                v-model="data.ravenflyShopName"
                @input="changeData"
            />
          </div>
        </template>

      </template>


    </div>

    <template v-if="data.shopChild !== '' && defaultShops.opencart === data.shopChild.name || defaultShops.woocommerce === data.shopChild.name || defaultShops.wish === data.shopChild.name">
      <div class="custom-row">
        <div class="custom-col"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['shops_entrance'])"></span>
          <div class="toggle-block__details-title">
            {{$t('shops_entrance.localization_value.value')}}
          </div>
        </div>
      </div>

      <div class="custom-row">
        <div class="custom-col custom-col--50 custom-col--xl-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_login'])"></span>
          <DefaultInput
                  class="white"
                  v-bind:class="{'ui-no-valid': dataValidate.validation.shopLogin}"
                  :error="dataValidate.validation.shopLogin"
                  :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.shopLogin : $t(`${dataValidate.validationTranslate.shopLogin}.localization_value.value`)"
                  :type="'text'"
                  :label="$t('common_login.localization_value.value')"
                  v-model="data.shopLogin"
                  @input="changeData"
          />
        </div>
        <div class="custom-col custom-col--50 custom-col--xl-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['login_password'])"></span>
          <DefaultInput
                  class="white"
                  v-bind:class="{'ui-no-valid': dataValidate.validation.shopPassword}"
                  :error="dataValidate.validation.shopPassword"
                  :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.shopPassword : $t(`${dataValidate.validationTranslate.shopPassword}.localization_value.value`)"
                  :type="'text'"
                  :label="$t('login_password.localization_value.value')"
                  v-model="data.shopPassword"
                  @input="changeData"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "OtherShop",

    components: {
      DefaultInput,
      DefaultSelect,
    },

    props: ['shopOther', 'defaultShops', 'validate'],

    data() {
      return {
        data: this.shopOther,
        dataValidate: this.validate,
      }
    },

    watch: {
      validate(validate) {
        this.dataValidate = validate
      },
      shopOther(newVal) {
        this.data = newVal
      },
    },

    methods: {
      changeData() {
        this.$emit('input', this.data)
      },

      changeShopType(val) {
        this.data.shopChild = val
        this.data.shopLogin = ''
        this.data.shopPassword = ''
        this.changeData()
      }
    }
  }
</script>

<style lang="scss">

</style>
