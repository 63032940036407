
<template>
  <div class="shops-table">

    <ShopsHead
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeFilter="changeFilter"
        :showFilter="showFilter"
        :typeShops="dataTypeShops"
        :adminTabs="adminTabs"
        @changeTab="changeTab"
        :countFilterParams="countFilterParams"
    />
    <div class="table-filter-wrap">

      <ShopsFilter
          :showFilter="showFilter"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
          :filterGetParams="filterGetParams"
          :typeShopsFilter="typeShopsFilter"
      />

      <ShopsTable
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              :countFilterParams="countFilterParams"
              :adminTabs="adminTabs"
              @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>

  </div>
</template>

<script>
  import ShopsHead from "./ShopsHead/ShopsHead.vue";
  import ShopsFilter from "./ShopsFilter/ShopsFilter.vue";
  import ShopsTable from "./ShopsTable/ShopsTable.vue";

  export default {
    name: "ShopsTablePageAdmin",
    components: {
      ShopsHead,
      ShopsFilter,
      ShopsTable,
    },

    props: [
      'typeShops',
      'countFilterParams',
      'filterGetParams',
      'typeShopsFilter',
      'adminTabs',
    ],

    watch: {
      typeShops(newVal) {
        this.dataTypeShops = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
    },

    data() {
      return {
        dataTypeShops: this.typeShops,
        showFilter: false,
        filterId: '',
        filterName: '',
        forPage: 10,
        page: 1,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },

      changeTab(index) {
        this.$emit('changeTab', index)
      },

      changeFilter(data) {
        this.$emit('changeFilter', data)
      },
    }
  }
</script>

<style lang="scss">

</style>
