<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['shops_shopName'])"></span>
        <DefaultInput
            :label="$t('shops_shopName.localization_value.value')"
            :type="'text'"
            v-model="name"
        />
      </div>

      <div class="table-filter__item"
           v-if="typeShops.length > 0 && typeShops[0].activeTab"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['shop_type'])"></span>
        <DefaultSelect
                :options="shopTypesFilter"
                :label="$t('shop_type.localization_value.value')"
                @change="changeShopTypesFilter"
                :optionsLabel="'fullName'"
                :selected="shopActive"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['common_date'])"></span>
        <DatePickerDefault
                :label="$t('common_date.localization_value.value')"
                v-model="date">
          <template slot="body">
            <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy ~ mm/dd/yy'"
            ></date-picker>
            <!---->
          </template>
        </DatePickerDefault>
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker';
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "ShopsFilter",
    components: {
      FilterBlock,
      MainButton,
      DefaultInput,
      DatePickerDefault,
      DatePicker,
      DefaultSelect,
    },

    props: [
      'showFilter',
      'filterGetParams',
      'typeShops',
      'typeShopsFilter',
    ],

    mixins: [filterFunc],

    data(){
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        name: this.filterGetParams.name ? this.filterGetParams.name : '',
        shopId: this.filterGetParams.shopId ? this.filterGetParams.shopId : '',
        shopTypesFilter: this.generateShopsTypeInFilter(this.typeShopsFilter),

        shopActive: '',
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.name = newVal.name ? newVal.name : ''
        this.shopId = newVal.shopId ? newVal.shopId : ''

        this.checkShopSelectActive()
      },

      typeShopsFilter(newVal) {
        this.shopTypesFilter = this.generateShopsTypeInFilter(newVal)

        this.checkShopSelectActive()
      }
    },


    methods: {
      checkShopSelectActive() {
        if(this.shopId === '') return this.shopActive = ''

        this.shopTypesFilter.map((item) => {
          if (item.id === parseInt(this.shopId)) {
            this.shopActive = item.currentTranslate.label
          }
        })
      },

      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, ['date', 'name', 'shopId'])

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, ['name', 'shopId'])
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      changeShopTypesFilter(val) {
        this.shopId = val.id
      }

    },
  }
</script>

<style scoped>

</style>
