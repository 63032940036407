<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
          :backgroundImage="'shops'"
          class="shops"
      >
        <template slot="body">
          <div class="card-detail-left__mail">
            skladusa.pp@gmail.com
          </div>
        </template>
      </CardLeftBlock>
    </div>
    <div class="detail-page__right">
      <ShopsCreation v-if="this.$store.getters.getShopTypesParent.length > 0"/>
    </div>
  </div>

</template>

<script>

  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  // import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import ShopsCreation from "./ShopsCreation/ShopsCreation";

  export default {
    name: "ShopsCreationUser",
    components: {
      CardLeftBlock,
      // TooltipBtn,
      ShopsCreation,
    }
  }
</script>

<style lang="scss">

</style>
