import { render, staticRenderFns } from "./EbayShop.vue?vue&type=template&id=672c9055"
import script from "./EbayShop.vue?vue&type=script&lang=js"
export * from "./EbayShop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports