var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup-modal custom-popup active-perm-api",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('span',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['shops_activatePermissionAPI'])}}}),_vm._v(" "+_vm._s(_vm.$t('shops_activatePermissionForAPI.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"custom-popup__content",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'shops_toGetAnAccessTo',
        'shops_pleaseClick',
        'shops_hereAndGiveA',
        'shops_code',
        'common_cancel'
      ])}}}),_c('div',{staticClass:"custom-popup__txt active-perm-api__txt"},[_vm._v(" "+_vm._s(_vm.$t('shops_toGetAnAccessTo.localization_value.value'))+" "),_c('a',{staticClass:"site-link black",attrs:{"href":_vm.$store.getters.getShopsPermissionData.shopsPermissionLink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('shops_pleaseClick.localization_value.value'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('shops_hereAndGiveA.localization_value.value'))+" ")]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--33 custom-col--xs-100"},[_c('DefaultInput',{class:{'ui-no-valid': _vm.validation.code},attrs:{"error":_vm.validation.code,"errorTxt":_vm.serverError ? _vm.validationTxt.code : _vm.$t(`${_vm.validationTranslate.code}.localization_value.value`),"type":'email',"label":_vm.$t('shops_code.localization_value.value')},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"custom-popup__btn align-items-center d-flex mt-5"},[_c('span',{staticClass:"site-link site-link--alt custom-popup__btn-i mr-4",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),_c('MainButton',{staticClass:"custom-popup__btn-i wfc",class:{'disabled-btn' : _vm.$store.getters.getShopBtn},attrs:{"value":_vm.$t('shops_sendCode.localization_value.value')},nativeOn:{"click":function($event){return _vm.sendPermissionsCode.apply(null, arguments)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }