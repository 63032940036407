<template>
  <CardRightBlock>
    <template slot="header">
      <div class="order-create__head">
        <div class="order-create__head-line">
          <div class="order-create__header-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_backToAllShops'])"></span>
            <router-link :to="$store.getters.GET_PATHS.mainSettingsShops" class="order-create__head-back">
              <LinkBack
                      :value="$t('shops_backToAllShops.localization_value.value')"
              />
            </router-link>
          </div>
        </div>
        <div class="order-create__head-title"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['shops_editAShop'])"></span>
          {{$t('shops_editAShop.localization_value.value')}}
        </div>
        <div class="order-create__head-txt"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['shops_pleaseFillOutAllEdit'])"></span>
          {{$t('shops_pleaseFillOutAllEdit.localization_value.value')}}
        </div>
      </div>
    </template>
    <template slot="body">
      <div class="order-create__section">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['shops_generalInformation'])"></span>
          {{$t('shops_generalInformation.localization_value.value')}}
        </div>
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_shopName'])"></span>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.shopName}"
                    :error="validation.shopName"
                    :errorTxt="serverError ? validationTxt.shopName : $t(`${validationTranslate.shopName}.localization_value.value`)"
                    :type="'text'"
                    :label="$t('shops_shopName.localization_value.value')"
                    v-model="shopName"
            />
          </div>
          <div v-if="shopChoose !== shopsTypes.ukrainiantreasures.type" class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_linkToShop'])"></span>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.shopLink}"
                    :error="validation.shopLink"
                    :errorTxt="serverError ? validationTxt.shopLink : $t(`${validationTranslate.shopLink}.localization_value.value`)"
                    :type="'text'"
                    :label="$t('shops_linkToShop.localization_value.value')"
                    v-model="shopLink"
            />
          </div>
        </div>

        <div class="order-create__row custom-row" v-if="sendTrackingGroupOptions.length">
          <div class="order-create__col custom-col custom-col--66 custom-col--xl-66 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate([
                'shops_etsyTrackSetting',
                ETSY_SHOP_TRACK_SETTINGS.ETSY_SHOP_TRACK_SETTING_SEND_CREATED.translation,
                ETSY_SHOP_TRACK_SETTINGS.ETSY_SHOP_TRACK_SETTING_SEND_ARRIVED_MINNESOTA.translation,
                ETSY_SHOP_TRACK_SETTINGS.ETSY_SHOP_TRACK_SETTING_DO_NOT_SEND.translation,
                ])"></span>
            <DefaultSelect
                :label="$t('shops_etsyTrackSetting.localization_value.value')"
                :options="sendTrackingGroupOptions"
                :selected="sendTrackingGroup"
                :otherValue="'translation'"
                @change="changeSendTrackingGroup"
            />
          </div>
        </div>

        <div class="order-create__row custom-row" v-if="updateProductQuantityOptions.length">
          <div class="order-create__col custom-col custom-col--66 custom-col--xl-66 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_etsyUpdateProductQuantity',])"></span>
            <DefaultSelect
                :label="$t('shops_etsyUpdateProductQuantity.localization_value.value')"
                :options="updateProductQuantityOptions"
                :selected="updateProductQuantity"
                :optionsLabel="'translation'"
                @change="changeUpdateProductQuantity"
            />
          </div>
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_uploadOrdersAPI'])"></span>
            <DefaultCheckbox
                :label="$t('shops_uploadOrdersAPI.localization_value.value')"
                v-model="loadOrdersFromAPI"
            />
          </div>
        </div>


        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col">
            <div class="order-create__toggle-block toggle-block">
              <div class="toggle-block__type">
                <div class="toggle-block__type-title">
                </div>
                <div class="toggle-block__type-list">
                  <template
                      v-for="(item, index) in $store.getters.getShopTypesParent">
                    <div class="toggle-block__type-item"
                         v-if="!(!_.has(currentPermissions, PERMISSIONS.SHOPS_UKRAINIAN_TREASURES_TYPE) && item.shop.name === shopsTypes.ukrainiantreasures.type)"
                         :key="index"
                    >
                      <RadioLabelPicture
                          :label="item.shop.currentTranslate.label"
                          :value="item.shop.name === shopChoose ? true : false"
                          name="'shopType'"
                          @change="shopTypeChange(index)"
                      >
                        <template slot="ico" v-if="shopsTypes[item.shop.name] !== undefined && shopsTypes[item.shop.name].ico !== ''">
                          <img v-bind:class="{'ukr-ico': item.shop.name === shopsTypes.ukrainiantreasures.type}" :src="shopsTypes[item.shop.name].ico" alt="ico">
                        </template>
                      </RadioLabelPicture>
                      <div v-if="item.shop.id === 1" style="margin-bottom: 24px;padding-left: 27px;">{{$t('shops_etsyTradeMark.localization_value.value')}}</div>
                    </div>
                  </template>

                </div>
              </div>
              <div class="toggle-block__details">
                <div class="toggle-block__details-head">
                  <div class="toggle-block__details-title"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit" @click="editTranslate(['shops_details'])"></span>
                    {{$t('shops_details.localization_value.value')}}
                  </div>
                  <ToggleGuideBlock
                      :shopGuideKey="shopChoose + (shopChoose === shopsTypes.other.type ? '-' + shopOther.shopChild.name : '') + '-shop-single-guide'"
                  />
                </div>

                <div class="toggle-block__details-body">

                  <EtsyShop
                          :validate="{validation, validationTranslate, validationTxt, serverError}"
                          :shopEtsy="shopEtsy"
                          v-model="shopEtsy"
                          v-if="shopChoose === shopsTypes.etsy.type"
                  />
                  <AmazonShop
                          :validate="{validation, validationTranslate, validationTxt, serverError}"
                          :shopAmazon="shopAmazon"
                          v-model="shopAmazon"
                          v-if="shopChoose === shopsTypes.amazonmws.type"
                  />
                  <EbayShop  v-if="shopChoose === shopsTypes.ebay.type"/>
                  <UkrainiantreasuresShop
                      v-if="shopChoose === shopsTypes.ukrainiantreasures.type"
                      :isEdit="true"
                      :shopName="shopName"
                      :shopUkrainiantreasures="shopUkrainiantreasures"
                      :validate="{validation, validationTranslate, validationTxt, serverError}"
                      v-model="shopUkrainiantreasures"
                  />
                  <OtherShop
                          :validate="{validation, validationTranslate, validationTxt, serverError}"
                          :defaultShops="defaultShops"
                          :shopOther="shopOther"
                          v-model="shopOther"
                          v-if="shopChoose === shopsTypes.other.type"/>
                </div>


              </div>
            </div>
          </div>
        </div>


        <!--<div class="order-create__row custom-row">-->
          <!--<div class="order-create__col custom-col"-->
               <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
            <!--<span class="admin-edit" @click="editTranslate(['shops_defaultShop'])"></span>-->
            <!--<DefaultCheckbox-->
                    <!--:label="$t('shops_defaultShop.localization_value.value')"-->
                    <!--v-model="isDefaultShop"-->
            <!--/>-->
          <!--</div>-->
        <!--</div>-->

        <div class="order-create__row custom-row" v-if="isAdmin">
          <div class="order-create__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_isTradeMark'])"></span>
            <DefaultCheckbox
                    :label="$t('shops_isTradeMark.localization_value.value')"
                    v-model="isTradeMark"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="order-create__footer">
        <div class="order-create__footer-link"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_cancel'])"></span>
          <router-link :to="$store.getters.GET_PATHS.mainSettingsShops"
                       class="site-link site-link--alt"
          >
            {{$t('common_cancel.localization_value.value')}}
          </router-link>

        </div>

        <div class="order-create__footer-btn ml-auto"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
              'shops_changeShop', 'shops_getAPIPermission', 'shops_getAPIPermTooltipTitle', 'shops_getAPIPermTooltipText'
              ])"></span>
          <template
                  v-if="((shopsTypes.other.type !== shopChoose && shopChoose !== shopsTypes.ukrainiantreasures.type) || (shopsTypes.other.type === shopChoose && shopOther.shopChild.name === defaultShops.shopify))">
            <MainButton
                    class="order-create__footer-btn-i secondary wfc"
                    :value="$t('shops_changeShop.localization_value.value')"
                    @click.native="changeShop"
                    v-bind:class="{'disabled-btn' : $store.getters.getShopBtn}"
            />

            <MainButton
                    v-if="$store.getters.getShopsItem.shop_oauth_param.key_and_token_exist !== 0"
                    class="order-create__footer-btn-i"
                    :value="$t('shops_deletePermission.localization_value.value')"
                    :tooltip="true"
                    @click.native="removePermission($store.getters.getShopsItem.id)"
                    v-bind:class="{'disabled-btn' : $store.getters.getShopBtn}"
            />
            <MainButton
                    v-else
                    class="order-create__footer-btn-i"
                    :value="$t('shops_getAPIPermission.localization_value.value')"
                    :tooltip="true"
                    @click.native="getApiPermission"
            >
              <template slot="tooltip">
                <p>
                  <b>{{$t('shops_getAPIPermTooltipTitle.localization_value.value')}}</b>
                </p>
                <p>
                  {{$t('shops_getAPIPermTooltipText.localization_value.value')}}
                </p>
              </template>
            </MainButton>

            <ActivatePermissionAPIPopup
                    @close="closeActivatePermission"
                    @done="doneActivatePermission"
                    v-if="isModalActivatePermission"
            />

          </template>
          <template v-else>
            <MainButton
                    class="order-create__footer-btn-i wfc"
                    :value="$t('shops_changeShop.localization_value.value')"
                    @click.native="changeShop"
                    v-bind:class="{'disabled-btn' : $store.getters.getShopBtn}"
            >
            </MainButton>
          </template>
        </div>
      </div>
    </template>
  </CardRightBlock>

</template>

<script>
  import LinkBack from "../../../../../../UI/buttons/LinkBack/LinkBack";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import RadioLabelPicture from "../../../../../../UI/radiobuttons/RadioLabelPicture/RadioLabelPicture";
  import EtsyShop from "../../../components/EtsyShop/EtsyShop";
  import AmazonShop from "../../../components/AmazonShop/AmazonShop";
  import EbayShop from "../../../components/EbayShop/EbayShop";
  import OtherShop from "../../../components/OtherShop/OtherShop";
  import CardRightBlock from "../../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import ActivatePermissionAPIPopup from "../../../../popups/ActivatePermissionAPIPopup/ActivatePermissionAPIPopup";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {
    ETSY_SHOP_TRACK_SETTINGS,
    ETSY_SHOP_TRACK_SETTINGS_ARRAY
  } from "../../../../../../../staticData/staticVariables";
  import ToggleGuideBlock from "../../../components/ToggleGuideBlock/ToggleGuideBlock";
  import UkrainiantreasuresShop
    from "@/components/modules/ShopsModule/components/components/UkrainiantreasuresShop/UkrainiantreasuresShop";


  export default {
    name: "ShopsEditing",
    components: {
      UkrainiantreasuresShop,
      ToggleGuideBlock,
      DefaultSelect,
      LinkBack,
      MainButton,
      DefaultInput,
      DefaultCheckbox,
      RadioLabelPicture,
      EtsyShop,
      AmazonShop,
      EbayShop,
      OtherShop,
      CardRightBlock,
      ActivatePermissionAPIPopup,
    },

    data() {
      return {
        idShopCreationWithAPI: '',
        name: '',
        link: '',
        loadOrdersFromAPI: true,
        shopSetting: '',
        shopChoose: this.$store.getters.getShopsItem.shop_type.name,
        shopChooseID: this.$store.getters.getShopsItem.shop_type.id,

        shopsTypes: this.$store.getters.getDefaultShopsTypes,

        isDefaultShop: false,
        isTradeMark: false,

        sendTrackingGroup: null,
        sendTrackingGroupOptions: [],

        updateProductQuantity: null,
        updateProductQuantityOptions: [],

        shopName: '',
        shopLink: '',

        shopEtsy: {
          shopId: '',
        },

        shopAmazon: {
          sellerId: '',
          amazonToken: '',
        },

        shopEbay: {

        },

        shopUkrainiantreasures: {
          linkShopName: '',
        },

        shopOther: {
          shopChild: '',
          shopApiUrl: '',
          shopApiKey: '',
          shopApiSecret: '',
          shopLogin: '',
          shopPassword: '',
          ravenflyShopName: '',
          referenceText: '',
        },

        defaultShops: {
          opencart: 'opencart',
          woocommerce: 'woocommerce',
          tilda: 'tilda',
          raverly: 'raverly',
          nothingAbove: 'nothing_above',
          shopify: 'shopify',
          wish: 'wish',
        },

        ETSY_SHOP_TRACK_SETTINGS_ARRAY: ETSY_SHOP_TRACK_SETTINGS_ARRAY,
        ETSY_SHOP_TRACK_SETTINGS: ETSY_SHOP_TRACK_SETTINGS,

        serverError: false,

        validation: {
          shopName: false,
          shopLink: false,
          sellerId: false,
          amazonToken: false,
          shopApiUrl: false,
          shopLogin: false,
          shopPassword: false,
          shopApiKey: false,
          shopApiSecret: false,
          ravenflyShopName: false,
          linkShopName: false,
          referenceText: false,
        },

        validationTranslate: {
          shopName: '',
          shopLink: '',
          sellerId: '',
          amazonToken: '',
          shopApiUrl: '',
          shopLogin: '',
          shopPassword: '',
          shopApiKey: '',
          shopApiSecret: '',
          ravenflyShopName: '',
          linkShopName: '',
          referenceText: '',
        },

        validationTxt: {
          shopName: false,
          shopLink: false,
          sellerId: false,
          amazonToken: false,
          shopApiUrl: false,
          shopLogin: false,
          shopPassword: false,
          shopApiKey: false,
          shopApiSecret: false,
          ravenflyShopName: false,
          linkShopName: false,
          referenceText: false,
        },

        isModalActivatePermission: false,
      }
    },

    mounted() {
      let shopData = this.$store.getters.getShopsItem

      this.shopName = shopData.shop_name
      this.shopChooseID = shopData.type_id
      this.isDefaultShop = shopData.shop_g_a_params.ga_shop_default === 1
      this.isTradeMark = shopData.shop_param.is_trade_mark === '1'
      this.shopLink = shopData.shop_param.shop_link

      this.shopUkrainiantreasures.linkShopName = shopData.ut_shop_url

      this.loadOrdersFromAPI = shopData.shop_state.load_orders_from_api === 1


      if(shopData.shop_type.parent_type_id !== null) {
        this.$store.getters.getShopTypesParent.map((item, index) => {
          if(item.shop.id === shopData.shop_type.parent_type_id) {
            console.log(index);
            this.shopTypeChange(index)
          }
        })

        this.shopOther.shopChild = {name : shopData.shop_type.name, id: shopData.shop_type.id}
      }

      if(shopData.shop_type.parent_type_id !== null) {
        this.shopOther.shopApiUrl = shopData.shop_param.remote_shop_url === null ? '' : shopData.shop_param.remote_shop_url
        this.shopOther.shopApiKey = shopData.shop_param.remote_shop_api_key === null ? '' : shopData.shop_param.remote_shop_api_key
        this.shopOther.shopApiSecret = shopData.shop_param.remote_shop_api_secret === null ? '' : shopData.shop_param.remote_shop_api_secret
        this.shopOther.shopLogin = shopData.shop_param.shop_login === null ? '' : shopData.shop_param.shop_login
        this.shopOther.shopPassword = shopData.shop_param.shop_password === null ? '' : shopData.shop_param.shop_password
        this.shopOther.referenceText = shopData.shop_param.reference_text === null ? '' : shopData.shop_param.reference_text

      } else if(shopData.shop_type.name === this.shopsTypes.amazonmws.type) {

        this.shopAmazon.sellerId = shopData.shop_param.remote_shop_api_key
        this.shopAmazon.amazonToken = shopData.shop_param.remote_shop_api_secret
      }

      if (shopData.shop_type.name === this.shopsTypes.etsy.type) {
        this.shopTypeChange(0)
        this.sendTrackingGroup = shopData?.settings?.send_tracking_group ? shopData.settings.send_tracking_group : null
        let translationItem = this.ETSY_SHOP_TRACK_SETTINGS_ARRAY.find(findItem => {
          return findItem.id === this.sendTrackingGroup.id
        })
        this.sendTrackingGroup.translation = translationItem ? translationItem.translation : ''

        this.updateProductQuantity = shopData?.settings?.update_product_quantity ? shopData.settings.update_product_quantity : null
        this.updateProductQuantity ? this.updateProductQuantity.translation = this.updateProductQuantity.currentTranslate.label : null
      }

      if (shopData.shop_type.name === this.defaultShops.raverly) {
        this.shopOther.ravenflyShopName = shopData.shop_param.remote_shop_id !== null ? shopData.shop_param.remote_shop_id : ''
      }

      shopData.shop_param.remote_shop_id !== null ? this.shopEtsy.shopId = shopData.shop_param.remote_shop_id : false

    },

    methods: {
      shopTypeChange(index) {
        let shops = this.$store.getters.getShopTypesParent

        shops.map((item, index) => {
          shops[index].active = false
        })

        shops[index].active = true

        this.$store.commit('setShopTypesParent', shops)

        if (shops[index].shop?.settings?.send_tracking_group) {
          this.sendTrackingGroupOptions = shops[index].shop.settings.send_tracking_group

          this.sendTrackingGroupOptions = this.sendTrackingGroupOptions.map(item => {
            let translationItem = this.ETSY_SHOP_TRACK_SETTINGS_ARRAY.find(findItem => {
              return findItem.id === item.id
            })

            item.translation = translationItem ? translationItem.translation : ''
            this.sendTrackingGroup = (item.is_default && this.sendTrackingGroup === null) ? item : this.sendTrackingGroup

            return item
          })
        }
        else {
          this.sendTrackingGroupOptions = []
        }

        if (shops[index].shop?.settings?.update_product_quantity) {
          this.updateProductQuantityOptions = shops[index].shop.settings.update_product_quantity

          this.updateProductQuantityOptions = this.updateProductQuantityOptions.map(item => {
            item.translation = item.currentTranslate.label
            this.updateProductQuantity = (item.is_default && this.updateProductQuantity === null) ? item : this.updateProductQuantity
            return item
          })
        }
        else {
          this.updateProductQuantityOptions = []
        }

        this.shopChoose = shops[index].shop.name
        this.shopChooseID = shops[index].shop.id
      },


      changeShop() {
        let userId = this.$store.getters.getUserProfile.id
        let shopTypeId = this.shopChoose === this.shopsTypes.other.type ? this.shopOther.shopChild.id : this.shopChooseID

        let data = {
          shop_name: this.shopName,
          type_id: shopTypeId,
          user_id: userId,
          gaParameter: {
            ga_shop_default: this.isDefaultShop ? 1 : 0
          },
        }

        data.shopState = {
          load_orders_from_api: this.loadOrdersFromAPI ? 1 : 0
        }

        switch (this.shopChoose) {

          case this.shopsTypes.etsy.type:
            data.shopParam = {
              shop_link: this.shopLink,
              remote_shop_id: this.shopEtsy.shopId,
            }
            data.settings = {
              send_tracking_group: this.sendTrackingGroup ? { id: this.sendTrackingGroup.id } : null,
              update_product_quantity: this.updateProductQuantity ? { id: this.updateProductQuantity.id } : null,
            }
            break;

          case this.shopsTypes.amazonmws.type:
            data.shopParam = {
              shop_link: this.shopLink,
              remote_shop_api_key: this.shopAmazon.sellerId,
              remote_shop_api_secret: this.shopAmazon.amazonToken,
            }
            break;

          case this.shopsTypes.ebay.type:
            data.shopParam = {
              shop_link: this.shopLink,
            }
            break;

          case this.shopsTypes.ukrainiantreasures.type:
            data.shopParam = {
              shop_link: this.shopLink,
            }
            data.shop_slug = this.shopUkrainiantreasures.linkShopName
            break;

          case this.shopsTypes.other.type:
            data.shopParam = this.shopChildParams()
            break;
        }

        data.shopParam['is_trade_mark'] = this.isTradeMark ? '1' : '0'

        this.skipValidation()

        let shopId = this.$route.params.id

        this.$store.dispatch('updateShops', {id: shopId, data: data}).then((response) => {
          if(this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
            // console.log(response);


            this.$router.push(this.$store.getters.GET_PATHS.mainSettingsShops + this.$store.getters.getShopsFilter)

            setTimeout(() => {
              this.openNotify('success', 'common_notificationStoreChanged')
            }, 200)


          } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            this.serverError = true

            errors['shop_name'] ? ( this.validation.shopName = true,                              this.validationTxt.shopName = errors['shop_name'][0] )                  : false;
            errors['shop_slug'] ? ( this.validation.linkShopName = true,                          this.validationTxt.linkShopName = errors['shop_slug'][0] )              : false;
            errors['shopParam.shop_link'] ? ( this.validation.shopLink = true,                    this.validationTxt.shopLink = errors['shopParam.shop_link'][0] )                            : false;
            errors['shopParam.remote_shop_api_key'] ? ( this.validation.sellerId = true,          this.validationTxt.sellerId =      this.$t('error_required.localization_value.value') )        : false;
            errors['shopParam.remote_shop_api_key'] ? ( this.validation.shopApiKey = true,        this.validationTxt.shopApiKey =    this.$t('error_required.localization_value.value') )        : false;
            errors['shopParam.remote_shop_api_secret'] ? ( this.validation.amazonToken = true,    this.validationTxt.amazonToken =   this.$t('error_required.localization_value.value') )  : false;
            errors['shopParam.remote_shop_api_secret'] ? ( this.validation.shopApiSecret = true,  this.validationTxt.shopApiSecret = this.$t('error_required.localization_value.value') )  : false;
            errors['shopParam.remote_shop_url'] ? ( this.validation.shopApiUrl = true,            this.validationTxt.shopApiUrl =    this.$t('error_required.localization_value.value') )          : false;
            errors['shopParam.shop_login'] ? ( this.validation.shopLogin = true,                  this.validationTxt.shopLogin =     this.$t('error_required.localization_value.value') )                : false;
            errors['shopParam.shop_password'] ? ( this.validation.shopPassword = true,            this.validationTxt.shopPassword =  this.$t('error_required.localization_value.value') )          : false;
            errors['shopParam.reference_text'] ? ( this.validation.referenceText = true,            this.validationTxt.referenceText =  errors['shopParam.reference_text'][0] )  : false;
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      },

      getApiPermission() {
        let id = this.$route.params.id
        let defaultTypes = this.$store.getters.getDefaultShopsTypes
        let type = this.shopChoose === this.shopsTypes.other.type ? this.shopOther.shopChild.name : this.shopChoose

        this.$store.dispatch('getShopPermission', id).then((response) => {
          if(defaultTypes.ebay.type === type){
            window.location.href = response.data.data.shop_permission_link
          }
          if(defaultTypes.etsy.type === type){
            window.location.href = response.data.data.shop_permission_link
            // this.isModalActivatePermission = true
          }
          if(defaultTypes.shopify.type === type){
            localStorage.setItem('shopifyId', id)
            window.location.href = response.data.data.shop_permission_link
          }
          if (defaultTypes.amazonmws.type === type) {
            localStorage.setItem('amazonShopId', id)
            window.location.href = response.data.data.shop_permission_link
          }
        })
      },

      removePermission(id) {

        let text = {
          title: 'shops_deleteShopPermission',
          txt: 'shops_deleteShopPermissionQuest',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteShopPermission', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let shop = this.$store.getters.getShopsItem
              shop.shop_oauth_param.key_and_token_exist = 0
              setTimeout(() => {
                this.openNotify('success', 'common_notificationPermissionRemoved')
              }, 200)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },


      closeActivatePermission() {
        this.isModalActivatePermission = false
      },

      doneActivatePermission() {
        this.$emit('reloadShop')
        this.isModalActivatePermission = false
      },

      skipValidation() {
        this.serverError = false

        this.validation = {
          shopName: false,
          shopLink: false,
          sellerId: false,
          amazonToken: false,
          shopApiUrl: false,
          shopLogin: false,
          shopPassword: false,
          shopApiKey: false,
          shopApiSecret: false,
          referenceText: false,
        }
      },

      shopChildParams() {

        switch (this.shopOther.shopChild.name) {
          case this.defaultShops.opencart:
            return {
              remote_shop_id: 'opencart',
              shop_link: this.shopLink,
              remote_shop_url: this.shopOther.shopApiUrl,
              remote_shop_api_key: this.shopOther.shopApiKey,
              remote_shop_api_secret: this.shopOther.shopApiSecret,
              shop_login: this.shopOther.shopLogin,
              shop_password: this.shopOther.shopPassword,
            }
          case this.defaultShops.woocommerce:
            return {
              remote_shop_id: 'woocommerce',
              shop_link: this.shopLink,
              remote_shop_url: this.shopOther.shopApiUrl,
              remote_shop_api_key: this.shopOther.shopApiKey,
              remote_shop_api_secret: this.shopOther.shopApiSecret,
              shop_login: this.shopOther.shopLogin,
              shop_password: this.shopOther.shopPassword,
            }
          case this.defaultShops.tilda:
            return {
              remote_shop_id: 'tilda',
              shop_link: this.shopLink,
            }
          case this.defaultShops.raverly:
            return {
              remote_shop_id: this.shopOther.ravenflyShopName,
              shop_link: this.shopLink,
            }
          case this.defaultShops.shopify:
            return {
              remote_shop_id: 'shopify',
              shop_link: this.shopLink,
              shop_login: this.shopOther.shopLogin,
              shop_password: this.shopOther.shopPassword,
              reference_text: this.shopOther.referenceText,
            }
          case this.defaultShops.nothingAbove:
            return {
              remote_shop_id: 'nothingAbove',
              shop_link: this.shopLink,
              shop_login: this.shopOther.shopLogin,
              shop_password: this.shopOther.shopPassword,
            }
          case this.defaultShops.wish:
            return {
              remote_shop_id: 'wish',
              shop_link: this.shopLink,
              shop_login: this.shopOther.shopLogin,
              shop_password: this.shopOther.shopPassword,
            }
        }

      },

      changeSendTrackingGroup(val) {
        this.sendTrackingGroup = val
      },

      changeUpdateProductQuantity(val) {
        this.updateProductQuantity = val
      },

    }
  }
</script>

<style lang="scss">
.ukr-ico {
  max-width: 18px;
  position: relative;
  top: -1px;
}
</style>
