<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['common_date'])"></span>
        <DatePickerDefault
            :label="$t('common_date.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
              v-model="date"
              ref="datePicker"
              valueType="format"
              range
              :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
              :format="'MM/DD/YY'"
              :placeholder="'mm/dd/yy ~ mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['shops_shopName'])"></span>
        <DefaultInput
                :label="$t('shops_shopName.localization_value.value')"
                :type="'text'"
                v-model="name"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['shops_linkToShop'])"></span>
        <DefaultInput
            :label="$t('shops_linkToShop.localization_value.value')"
            :type="'text'"
            v-model="shopLink"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['shop_type'])"></span>
        <DefaultSelect
                :options="shopTypesFilter"
                :label="$t('shop_type.localization_value.value')"
                @change="changeShopTypesFilter"
                :optionsLabel="'fullName'"
                :selected="shopActive"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <UserSelect
                :userSelected="shopUser"
                @changeUser="changeUsersFilter"
                :typeSelect="'users'"
                :userEmail="true"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['shops_referenceText'])"></span>
        <DefaultInput
            :label="$t('shops_referenceText.localization_value.value')"
            :type="'text'"
            v-model="shopRefText"
        />
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  // import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import UserSelect from "../../../../../../coreComponents/UserSelect/UserSelect";

  export default {
    name: "ShopsFilter",
    components: {
      UserSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
      DefaultSelect,
      // SearchSelect,
    },

    props: [
      'showFilter',
      'filterGetParams',
      'typeShopsFilter',
    ],

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        name: this.filterGetParams.name ? this.filterGetParams.name : '',
        shop: this.filterGetParams.shop ? this.filterGetParams.shop : '',
        shopLink: this.filterGetParams.shopLink ? this.filterGetParams.shopLink : '',
        shopUser: this.filterGetParams.shopUser ? this.filterGetParams.shopUser : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        shopTypesFilter: this.generateShopsTypeInFilter(this.typeShopsFilter),
        shopRefText: this.filterGetParams.shopRefText ? this.filterGetParams.shopRefText : '',

        shopActive: '',
        options: [],
      }
    },

    created() {
      let interval = setInterval(() => {
        if(this.shopTypesFilter.length > 0){
          this.checkShopSelectActive()
          clearInterval(interval)
        }
      }, 50)
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.name = newVal.name ? newVal.name : ''
        this.shop = newVal.shop ? newVal.shop : ''
        this.shopLink = newVal.shopLink ? newVal.shopLink : ''
        this.shopUser = newVal.shopUser ? newVal.shopUser : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.shopRefText = newVal.shopRefText ? newVal.shopRefText : ''

        this.checkShopSelectActive()
      },

      typeShopsFilter(newVal) {
        this.shopTypesFilter = this.generateShopsTypeInFilter(newVal)
      }
    },

    methods: {

      checkShopSelectActive() {
        let flag = false
        this.shopTypesFilter.map((item) => {
          if (item.id === parseInt(this.shop)) {
            this.shopActive = item.currentTranslate.label
            flag = true
          }
        })

        if(!flag){
          this.shopActive = ''
        }
      },

      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, ['date', 'name', 'shop', 'shopLink', 'shopUser', 'userId', 'shopRefText'])

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, ['name', 'shop', 'shopLink', 'shopUser', 'userId', 'shopRefText'])
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      changeShopTypesFilter(val) {
        this.shop = val.id
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading)
      },

      changeUsersFilter(value) {
        if(value !== ''){
          this.userId = value.id
          this.shopUser = value.contacts[0].user_full_name
        } else {
          this.userId = ''
          this.shopUser = ''
        }
      },
    },
  }
</script>

<style scoped>

</style>
